import React from 'react'
import RoundedBottomContainer from './rounded-bottom-container'

const RegisteredRequestAccessStatus: React.FC = () => (
  <RoundedBottomContainer>
    <div className="flex flex-col items-center">
      <p className="px-0 sm:px-12 text-red">
        Thank you for registering! Your email was not found, a KP representative
        has been notified and will email you in the upcoming days to let you
        know about your event status.
      </p>
    </div>
  </RoundedBottomContainer>
)

export default RegisteredRequestAccessStatus
