import * as shared from 'pericles-shared'
import {
  GetPollInput,
  GetPollResponse,
  PostVoteInput,
  DefaultResponse,
  GetPollAverageInput,
  GetPollAverageResponse,
} from 'pericles-types'

export const getPoll = shared.callFunction<GetPollInput, GetPollResponse>(
  'getPoll'
)

export const postVote = shared.callFunction<PostVoteInput, DefaultResponse>(
  'postVote'
)

export const getPollAverage = shared.callFunction<
  GetPollAverageInput,
  GetPollAverageResponse
>('getPollAverage')
