import React from 'react'

const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.222 14.9999V13.4444C14.222 11.7261 12.8291 10.3333 11.1109 10.3333H4.8887C3.17048 10.3333 1.77759 11.7261 1.77759 13.4444V14.9999"
        stroke={props?.color ?? '#677083'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99966 7.22222C9.71788 7.22222 11.1108 5.82933 11.1108 4.11111C11.1108 2.39289 9.71788 1 7.99966 1C6.28144 1 4.88855 2.39289 4.88855 4.11111C4.88855 5.82933 6.28144 7.22222 7.99966 7.22222Z"
        stroke={props?.color ?? '#677083'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserIcon
