import React from 'react'

import Header from 'components/header'
import { Link } from 'react-router-dom'
import Footer from 'components/footer'

interface DocumentLayoutProps {
  title: string
  textElement: JSX.Element
}

const DocumentLayout: React.FC<DocumentLayoutProps> = ({
  title,
  textElement,
}) => {
  return (
    <div className="flex flex-col h-full">
      <div className="bg-deep-blue pb-4">
        <Header variant="simple" />
      </div>
      <div className="py-12 px-5 bg-white flex-grow xl:px-32">
        <Link
          to="/"
          className="font-avenir-next font-semibold"
          style={{
            color: '#0078B3',
          }}
        >
          &lt; Back
        </Link>
        <h4 className="font-avenir-next font-semibold text-black text-xl text-center mt-4">
          {title}
        </h4>
        <div className="mt-6 text-black font-avenir-next">{textElement}</div>
      </div>
      <Footer />
    </div>
  )
}

export default DocumentLayout
