import React, { useContext, useEffect, useState } from 'react'
import ChatContext from 'context/chat'

export interface AdditionalTapButton {
  label: string
  value?: string
  activated?: boolean
  content?: JSX.Element
}

interface TabButtonProps {
  activated?: boolean
  disabled?: boolean
  value?: string
  children: React.ReactNode
}

const TabButton: React.FC<TabButtonProps> = ({
  disabled,
  activated = false,
  children,
  value = '',
}) => {
  const { onClickMenuButton, lastClickedButtonValue } = useContext(ChatContext)
  const [activatedState, setActivatedState] = useState(activated)

  useEffect(() => {
    if (lastClickedButtonValue === value) {
      setActivatedState(true)
    } else {
      setActivatedState(false)
    }
  }, [lastClickedButtonValue, value])

  useEffect(() => {
    // Initial activated state
    if (activated) {
      onClickMenuButton(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disabledClass = disabled
    ? 'bg-transparent text-dolphin cursor-default'
    : 'bg-chat-tab-blue text-white'

  const activatedClass = activatedState
    ? 'bg-chat-tab-blue text-white'
    : 'bg-transparent text-dolphin'

  const handlerOnClickButton = () => {
    if (!disabled) {
      onClickMenuButton(value)
    }
  }

  return (
    <button
      type="button"
      onClick={handlerOnClickButton}
      className={`flex font-avenir-next font-semibold px-3 py-2 rounded-r-lg rounded-l-lg focus:outline-none ${
        disabled ? disabledClass : activatedClass
      }`}
    >
      {children}
    </button>
  )
}

export default TabButton
