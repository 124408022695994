import React from 'react'

import Button from 'components/button'
import arrowRight from 'assets/svg/arrow-right.svg'

interface SubmitProps {
  value: string
  disabled?: boolean
  large?: boolean
  showArrowIcon?: boolean
  onClick?: () => void
}

const Submit: React.FC<SubmitProps> = ({
  value,
  disabled,
  onClick,
  large = false,
  showArrowIcon = false,
}) => {
  return (
    <>
      <Button
        size={large ? 'large' : 'medium'}
        color="blue"
        onClick={onClick}
        disabled={disabled}
        submit
      >
        <>
          {value}
          {showArrowIcon ? (
            <img
              src={arrowRight}
              alt="register"
              className={`ml-4 ${disabled ? 'opacity-50' : ''}`}
            />
          ) : null}
        </>
      </Button>
    </>
  )
}

export default Submit
