import { AdditionalTapButton } from 'components/chat/tab-button'

export const CLICK_MENU_BUTTON_HANDLER_SET = 'chat:SET_ON_CLICK_MENU_BUTTON'
export const MENU_BUTTON_CLICKED = 'chat:MENU_BUTTON_CLICKED'
export const ADDITIONAL_BUTTONS_ADDED = 'chat:ADDITIONAL_BUTTONS_ADDED'
export const SET_STREAM_CHAT_USER_PROPS = 'chat:SET_STREAM_CHAT_USER_PROPS'
export const UPDATE_ONLINE_COUNTER = 'chat:UPDATE_ONLINE_COUNTER'
export const SET_IS_CHAT_ENABLED = 'chat:SET_IS_CHAT_ENABLED'

export interface ChatState {
  readonly onClickMenuButtonHandler?: (buttonValue: string) => void
  readonly additionalButtons?: AdditionalTapButton[]
  readonly lastClickedButtonValue?: string
  readonly userId?: string
  readonly streamChatUserToken?: string
  readonly userPhotoURL?: string
  readonly userName?: string
  readonly onlineCounter?: number
  readonly isEnabled?: boolean
}

// Set click menu button handler
export interface SetClickMenuButtonHandlerPayload {
  readonly onClickMenuButtonHandler: (buttonValue: string) => void
}

export interface SetClickMenuButtonHandlerAction {
  type: typeof CLICK_MENU_BUTTON_HANDLER_SET
  payload: SetClickMenuButtonHandlerPayload
}

// On click menu button
export interface OnClickMenuButtonPayload {
  readonly buttonValue: string
}

export interface OnClickMenuButtonAction {
  type: typeof MENU_BUTTON_CLICKED
  payload: OnClickMenuButtonPayload
}

// On add additional buttons
export interface AddAdditionalButtonsPayload {
  readonly additionalButtons?: AdditionalTapButton[]
}

export interface AddAdditionalButtonsAction {
  type: typeof ADDITIONAL_BUTTONS_ADDED
  payload: AddAdditionalButtonsPayload
}

// Create user token
export interface SetChatUserPropsPayload {
  readonly userId: string
  readonly streamChatUserToken: string
  readonly userName: string
  readonly userPhotoURL?: string
}

export interface SetChatUserPropsAction {
  type: typeof SET_STREAM_CHAT_USER_PROPS
  payload: SetChatUserPropsPayload
}

// Update online counter
export interface UpdateOnlineCounterPayload {
  readonly onlineCounter: number
}

export interface UpdateOnlineCounterAction {
  type: typeof UPDATE_ONLINE_COUNTER
  payload: UpdateOnlineCounterPayload
}

// Is chat enabled
export interface SetIsChatEnabledPayload {
  readonly isEnabled: boolean
}

export interface SetIsChatEnabledAction {
  type: typeof SET_IS_CHAT_ENABLED
  payload: SetIsChatEnabledPayload
}

// Chat reducer action types
export type ChatReducerActionTypes =
  | SetClickMenuButtonHandlerAction
  | OnClickMenuButtonAction
  | AddAdditionalButtonsAction
  | SetChatUserPropsAction
  | UpdateOnlineCounterAction
  | SetIsChatEnabledAction

// Chat reducer
export type ChatReducer = (
  state: ChatState,
  action: ChatReducerActionTypes
) => ChatState
