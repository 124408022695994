import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
} from 'react'
import * as shared from 'pericles-shared'
import { useNavigate } from 'react-router-dom'

import { AdditionalTapButton } from 'components/chat/tab-button'
import * as registrationApi from 'api/registration'
import * as chatApi from 'api/chat'
import ModalContext from 'context/modal'
import useEventDocumentInfo from 'hooks/use-event-document-info'
import CurrentEventContext from 'context/event'
import chatReducer, { initialState } from './reducer'
import * as actions from './actions'
import { ChatState } from './types'

const { firebase } = shared

type ChatContextProps = ChatState & {
  readonly onClickMenuButton: (buttonValue: string) => void
  readonly addClickMenuButtonHandler: (
    handler: (buttonValue: string) => void
  ) => void
  readonly setAdditionalButtons: (
    additionalButtons: AdditionalTapButton[]
  ) => void
  readonly updateOnlineUsersCounter: (usersOnline: number) => void
}

const defaultValue: ChatContextProps = {
  onClickMenuButton: () => {
    throw new Error('onClickMenuButton must be defined')
  },
  addClickMenuButtonHandler: () => {
    throw new Error('addClickMenuButtonHandler must be defined')
  },
  setAdditionalButtons: () => {
    throw new Error('setAdditionalButtons must be defined')
  },
  updateOnlineUsersCounter: () => {
    throw new Error('updateOnlineUsersCounter must be defined')
  },
}

const ChatContext = createContext<ChatContextProps>(defaultValue)
export default ChatContext

const STORAGE_CHAT_USER_INFO_KEY = 'chatUserInfo'
interface StorageChatUserInfo {
  userId: string | null
  streamChatUserToken: string | null
  firstName: string | null
  lastName: string | null
}

export const ChatProvider: React.FC = ({ children }) => {
  const user = shared.AuthContext.useContainer()
  const { showModal } = useContext(ModalContext)
  const [state, dispatch] = useReducer(chatReducer, initialState)
  const navigate = useNavigate()
  const [initialized, setInitialized] = useState(false)

  // Check if chat is enabled
  const event = CurrentEventContext.useContainer()
  const { chatEnabled } = useEventDocumentInfo(event!.id)
  useEffect(() => {
    dispatch(actions.setIsChatEnabled(chatEnabled))
  }, [chatEnabled])

  const {
    additionalButtons,
    lastClickedButtonValue,
    userId,
    userName,
    userPhotoURL,
    streamChatUserToken,
    onlineCounter,
    isEnabled,
  } = state

  const providerValue: ChatContextProps = {
    additionalButtons,
    lastClickedButtonValue,
    userId,
    userName,
    userPhotoURL,
    streamChatUserToken,
    onlineCounter,
    isEnabled,
    onClickMenuButton: (buttonValue) => {
      dispatch(actions.onClickMenuButton(buttonValue))
    },
    addClickMenuButtonHandler: (handler) => {
      dispatch(actions.setClickMenuButtonHandler(handler))
    },
    setAdditionalButtons: (buttons) => {
      dispatch(actions.addAdditionalButtons(buttons))
    },
    updateOnlineUsersCounter: (usersOnline) => {
      dispatch(actions.updateOnlineUsersCounter(usersOnline))
    },
  }

  // Log in into the chat
  useEffect(() => {
    if (isEnabled && !initialized) {
      if (user) {
        const asyncRequests = async () => {
          const chatUserInfoFromStorage = localStorage.getItem(
            STORAGE_CHAT_USER_INFO_KEY
          )
          const storedChatUserInfo = chatUserInfoFromStorage
            ? (JSON.parse(chatUserInfoFromStorage) as StorageChatUserInfo)
            : null

          if (storedChatUserInfo && storedChatUserInfo.userId !== user.uid) {
            storedChatUserInfo.streamChatUserToken = null
            storedChatUserInfo.userId = null
            storedChatUserInfo.firstName = null
            storedChatUserInfo.lastName = null
            localStorage.removeItem(STORAGE_CHAT_USER_INFO_KEY)
          }

          let chatUserToken = storedChatUserInfo?.streamChatUserToken

          if (!chatUserToken) {
            const userTokenData = await chatApi.createChatUserToken({
              userId: user.uid,
            })
            chatUserToken = userTokenData.chatUserToken
          }

          let firstName = storedChatUserInfo?.firstName
          let lastName = storedChatUserInfo?.lastName

          if (!firstName && !lastName) {
            const userAccessToken = await user.getIdToken()
            try {
              const basicUserInfo = await registrationApi.getBasicUserInfo({
                userEmail: user.email as string,
                accessToken: userAccessToken,
              })
              firstName = basicUserInfo.firstName
              lastName = basicUserInfo.lastName
            } catch (error) {
              showModal({
                title: 'Permission denied!',
                description: 'Please, register and log in again',
                confirmText: 'Go Home and log in',
                onConfirm: async () => {
                  await firebase.auth().signOut()
                  window.location.reload()
                },
              })
            }
          }

          let moderatorStr = ''
          // TODO: Move these hard coded values to db
          const moderatorEmails = [
            'eric@leftfieldlabs.com',
            'mackenzie.f.larson@kp.org',
            'nico.arcino@kp.org',
            'rimma@in-dialogue.co',
            'karin.c.cooke@kp.org',
          ]
          if (moderatorEmails.indexOf(user.email as string) > -1) {
            moderatorStr = ' (Host)'
          }

          const fullUserName = `${firstName} ${lastName}${moderatorStr}`

          localStorage.setItem(
            STORAGE_CHAT_USER_INFO_KEY,
            JSON.stringify({
              userId: user.uid,
              streamChatUserToken: chatUserToken,
              firstName,
              lastName,
            } as StorageChatUserInfo)
          )

          dispatch(
            actions.setChatUserProps(
              user.uid,
              fullUserName,
              chatUserToken,
              user.photoURL ??
                `https://getstream.io/random_svg/?id=quiet-rain-7&name=${firstName}+${lastName}`
            )
          )
        }

        asyncRequests()
        setInitialized(true)
      } else {
        showModal({
          title: 'Oops!',
          description: 'You need to log in to join the chat!',
          confirmText: 'Go Home and log in',
          onConfirm: async () => {
            await firebase.auth().signOut()
            navigate('/', { replace: true })
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])

  return (
    <ChatContext.Provider value={providerValue}>
      {children}
    </ChatContext.Provider>
  )
}
