import React from 'react'

const PollIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 1.6001C15.1202 1.6001 14.4002 2.3201 14.4002 3.2001V27.2001C14.4002 28.0801 15.1202 28.8001 16.0002 28.8001C16.8802 28.8001 17.6002 28.0801 17.6002 27.2001V3.2001C17.6002 2.3201 16.8802 1.6001 16.0002 1.6001ZM27.2002 14.4001C26.3202 14.4001 25.6002 15.1201 25.6002 16.0001V27.2001C25.6002 28.0801 26.3202 28.8001 27.2002 28.8001C28.0802 28.8001 28.8002 28.0801 28.8002 27.2001V16.0001C28.8002 15.1201 28.0802 14.4001 27.2002 14.4001ZM3.2002 9.6001C3.2002 8.7201 3.9202 8.0001 4.8002 8.0001C5.6802 8.0001 6.4002 8.7201 6.4002 9.6001V27.2001C6.4002 28.0801 5.6802 28.8001 4.8002 28.8001C3.9202 28.8001 3.2002 28.0801 3.2002 27.2001V9.6001Z"
        fill={props?.color ?? '#20A29E'}
      />
    </svg>
  )
}

export default PollIcon
