import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useSingletonStatus } from 'reactive-singleton'
import * as shared from 'pericles-shared'

import SpeakersSection from 'components/speakers-section'
import SpeakerSectionTypes from 'components/speakers-section/speaker-section-types'
import Footer from 'components/footer'
import Header from 'components/header'
import Chat from 'components/chat'
import { AdditionalTapButton } from 'components/chat/tab-button'
import SessionDetails from 'components/session-details'
import useEventDocumentInfo from 'hooks/use-event-document-info'

import headerLiveBg from 'assets/images/header-live-bg.jpg'
import Poll from 'components/poll'
import PollContext, { PollProvider } from 'context/poll'
import TechnicalDifficulties from 'components/technical-difficulties'
import CurrentEventContext from 'context/event'
import useEventProperties from 'hooks/use-event-properties'
import UserService from 'services/user-service'
import { Navigate } from 'react-router'
import { setLiveEventsAnalytics } from 'api/publish'
import usePresenceDetector from 'hooks/use-presence-detector'

const LiveEventContent: React.FC = () => {
  const { forceOffline } = usePresenceDetector()
  const [additionalChatButtonsList, setAdditionalChatButtonsList] = useState<
    AdditionalTapButton[]
  >([])
  const event = CurrentEventContext.useContainer()
  const { player, playerStreamURL } = useEventDocumentInfo(event!.id)
  const { eventState } = useEventProperties(event!.id)
  const { pollData } = useContext(PollContext)
  const userService = UserService.getInstance()
  const { user, setAttendEvent } = userService

  if (!user.attendLiveEvent && user.firstName && user.lastName && user.fbUser) {
    setAttendEvent()
    setLiveEventsAnalytics({
      firstName: user.firstName!,
      lastName: user.lastName!,
      email: user.fbUser?.email!,
      eventId: 'event2',
    })
  }

  useEffect(() => {
    const aboutTabButton: AdditionalTapButton = {
      label: 'About',
      value: 'about',
      activated: true,
      content: <SessionDetails className="mx-4 mt-10 mb-8" />,
    }

    // Controls the about button inside the Chat's tab menu
    const handlerOnResize = () => {
      if (window.innerWidth < 1184) {
        setAdditionalChatButtonsList([aboutTabButton])
      } else {
        setAdditionalChatButtonsList([])
      }
    }
    handlerOnResize()

    window.addEventListener('resize', handlerOnResize)
    return () => window.removeEventListener('resize', handlerOnResize)
  }, [])

  const videoPlayer = useCallback(() => {
    if (player === 'vimeo') {
      return (
        <iframe
          title="Video Viewer"
          src={playerStreamURL}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          className="flex absolute w-full h-full top-0 left-0"
          style={{
            background: 'rgba(0, 0, 0, 0.2)',
          }}
        />
      )
    }
    return undefined
  }, [player, playerStreamURL])()

  useEffect(() => {
    return () => {
      forceOffline()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preloader = (
    <div
      className="flex absolute w-full h-full top-0 left-0"
      style={{
        background: 'rgba(0, 0, 0, 0.2)',
      }}
    >
      <shared.Loader color="#F7F7F7" />
    </div>
  )

  return (
    <>
      <section
        className="bg-deep-blue xl:px-8"
        style={{
          background: `linear-gradient(180deg, rgba(0, 58, 113, 1.4) 0%, rgba(0, 59, 113, 0.35) 75.89%), url(${headerLiveBg}) #003B71`,
          backgroundSize: 'cover',
        }}
      >
        <Header variant="simple" />
        <div
          className="flex justify-between xl:my-6 mr-auto ml-auto flex-col xl:flex-row"
          style={{
            maxWidth: '1220px',
          }}
        >
          {/* Left */}
          <div className="flex mr-0 flex-col xl:mr-8">
            <div
              style={{
                padding: '56.25% 0 0 0',
                position: 'relative',
              }}
            >
              {eventState?.showError ? (
                <TechnicalDifficulties />
              ) : (
                <>
                  {preloader}
                  {videoPlayer}
                </>
              )}
            </div>
            <SessionDetails className="hidden mt-6 xl:block" />
          </div>
          {/* Right */}
          {pollData ? (
            <Poll />
          ) : (
            <Chat additionalButtons={additionalChatButtonsList} />
          )}
        </div>
      </section>
      <SpeakersSection sectionType={SpeakerSectionTypes.Live} />
      <Footer />
    </>
  )
}

const LiveEvent: React.FC = () => {
  const userService = UserService.getInstance()
  const userCheckStatus = useSingletonStatus(UserService) === 'ready'
  const { user } = userService

  const eventId = CurrentEventContext.useContainer()
  const { eventState } = useEventProperties(eventId!.id)

  useEffect(() => {
    userService.updateAccessData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userCheckStatus && user) {
    if (
      ((!user.fbUser || !user.isAdmin) && eventState?.currentState === 'pre') ||
      ((!user.fbUser || !user.isAllowed) &&
        eventState?.currentState === 'live') ||
      ((!user.fbUser || !user.isAdmin) && eventState?.currentState === 'post')
    ) {
      return <Navigate to="/" />
    }

    if (eventState?.currentState === 'post') {
      return <Navigate to="/" />
    }
  }

  return (
    <PollProvider>
      <LiveEventContent />
    </PollProvider>
  )
}

export default LiveEvent
