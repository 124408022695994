const getElementOffset = (element?: HTMLElement | null) => {
  if (element) {
    const rect = element.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  return { top: null, left: null }
}

export default getElementOffset
