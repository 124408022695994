import React from 'react'

interface ThinLineProps {
  className?: string
}

interface ThinLineProps {
  color?: 'deep-graphite' | 'white'
}

const ThinLine: React.FC<ThinLineProps> = ({
  className,
  color = 'deep-graphite',
}) => {
  const shadow = `shadow-${color}`
  return (
    <span
      className={`w-full m-auto border border-solid border-transparent block ${shadow} ${className}`}
    />
  )
}

export default ThinLine
