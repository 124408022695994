import React, { useState, useEffect, useContext } from 'react'
import { useSingletonStatus } from 'reactive-singleton'

import Header from 'components/header'
import MainFormTypes from 'components/main-form-display/main-form-types'
import MainFormDisplay from 'components/main-form-display'
import SpeakersSection from 'components/speakers-section'
import SpeakerSectionTypes from 'components/speakers-section/speaker-section-types'
import InfoSection from 'components/info-section'
import Footer from 'components/footer'

import * as shared from 'pericles-shared'
import * as types from 'pericles-types'
import UserService from 'services/user-service'
import { HeaderAreaProvider } from 'context/header-area'

import ModalContext from 'context/modal'
import CurrentEventContext from 'context/event'

const PreEvent: React.FC = () => {
  const { showModal } = useContext(ModalContext)
  const [formView, setFormView] = useState(
    <MainFormDisplay formType={MainFormTypes.Preloader} />
  )
  const eventId = CurrentEventContext.useContainer()
  const userService = UserService.getInstance()
  const userDataUpdated = useSingletonStatus(UserService) === 'ready'
  const { user } = userService

  const { data } = shared.useDocument<types.EventState>(
    `events/${eventId!.id}/EventProperties/EventState`,
    {
      listen: true,
      revalidateOnFocus: false,
      suspense: false,
    }
  )

  const showErrorModal = () => {
    showModal({
      title: 'Something went wrong',
      description:
        'There is an error with the server request. Try again in a few minutes!',
      confirmText: 'Try Again',
      onConfirm: () => {
        window.location.reload()
      },
    })
  }

  useEffect(() => {
    userService.updateAccessData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const { currentState } = data!
    if (user && userDataUpdated && data) {
      // Errors!
      if (user.hasError) {
        showErrorModal()
      }

      // Still waiting for data
      if (user.fbUser && user.infoPending) return

      if (currentState === 'pre') {
        // Logged out
        if (!user.fbUser) {
          setFormView(
            <MainFormDisplay formType={MainFormTypes.RegistrationForm} />
          )
        } else {
          // Logged in, registered, not on whitelist
          if (user.fbUser && user.isRegistered && !user.isInWhitelist) {
            setFormView(
              <MainFormDisplay
                formType={MainFormTypes.RegisteredAndRequestAccessForm}
              />
            )
            return
          }

          // Logged in, registered, requested access, access pending
          if (
            user.fbUser &&
            user.isRegistered &&
            user.isInWhitelist &&
            user.hasRequestedAccess &&
            user.isRestricted
          ) {
            setFormView(
              <MainFormDisplay
                formType={MainFormTypes.RegisteredAndNotInWhitelistForm}
              />
            )
            return
          }

          // Logged in, registered, requested access, access denied
          if (
            user.fbUser &&
            user.isRegistered &&
            user.isInWhitelist &&
            !user.hasRequestedAccess &&
            user.isRestricted
          ) {
            setFormView(
              <MainFormDisplay
                formType={MainFormTypes.RegisteredAndNotInWhitelistForm}
              />
            )
            return
          }

          if (user.fbUser && user.isRegistered && user.initialRegistration) {
            setFormView(<MainFormDisplay formType={MainFormTypes.Registered} />)
          }

          // Logged in, registered, access granted
          if (
            user.fbUser &&
            user.isInWhitelist &&
            !user.isRestricted &&
            !user.initialRegistration
          ) {
            setFormView(
              <MainFormDisplay formType={MainFormTypes.SignupCompleteForm} />
            )
          }
        }
      }

      if (currentState === 'live') {
        // Logged out
        if (!user.fbUser) {
          setFormView(
            <MainFormDisplay formType={MainFormTypes.LiveAndLoggedOutForm} />
          )
          return
        }

        // Logged in, no access
        if (
          (user.fbUser && user.isRestricted) ||
          (user.fbUser && !user.isInWhitelist)
        ) {
          setFormView(
            <MainFormDisplay
              formType={MainFormTypes.LiveAndLoggedInWithoutAccessForm}
            />
          )
          return
        }
        // Logged in, with access
        if (user.fbUser && !user.isRestricted) {
          setFormView(
            <MainFormDisplay
              formType={MainFormTypes.LiveAndLoggedInWithAccessForm}
            />
          )
        }
      }
    } else {
      setFormView(<MainFormDisplay formType={MainFormTypes.Preloader} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, data, userDataUpdated])

  return (
    <HeaderAreaProvider>
      <Header />
      {formView}
      <InfoSection />
      <SpeakersSection sectionType={SpeakerSectionTypes.Landing} />
      <Footer />
    </HeaderAreaProvider>
  )
}

export default PreEvent
