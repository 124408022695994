import React from 'react'

import GradientText from 'components/gradient-text'
import RoundedBottomContainer from './rounded-bottom-container'

const RequestAccessSentStatus: React.FC = () => {
  return (
    <RoundedBottomContainer>
      <div className="flex flex-col">
        <GradientText className="font-avenir-next text-inky text-2xl font-normal self-center">
          Thank you for registering!
        </GradientText>
        <p className="my-4 px-0 sm:px-12">
          Your email was not found, a KP representative has been notified and
          will email you in the upcoming days to let you know about your event
          status.
        </p>
        <a
          href="mailto:eventably-support@googlegroups.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          eventably-support@googlegroups.com
        </a>{' '}
      </div>
    </RoundedBottomContainer>
  )
}

export default RequestAccessSentStatus
