import React from 'react'

import Header from 'components/header'
import Footer from 'components/footer'

import headerLiveBg from 'assets/images/header-live-bg.jpg'
import thoughtcastLogo from 'assets/svg/thoughtcast-logo.svg'
import ThinLine from 'components/thin-line'
import CurrentEventContext from 'context/event'
import useEventDocumentInfo from 'hooks/use-event-document-info'
import UserService from 'services/user-service'
import SimpleUpcomingEvent from 'components/simple-upcoming-event'

const PostEvent: React.FC = () => {
  const event = CurrentEventContext.useContainer()
  const { onDemandVideoURL } = useEventDocumentInfo(event!.id)
  const { user } = UserService.getInstance()

  return (
    <>
      <section
        className="pb-32 px-2 xl:px-8"
        style={{
          background: `linear-gradient(180deg, rgba(0, 58, 113, 1.4) 0%, rgba(0, 59, 113, 0.35) 75.89%), url(${headerLiveBg}) #003B71`,
          backgroundSize: 'cover',
          backgroundPositionY: 'bottom',
        }}
      >
        <Header variant="simple" />
        <img
          src={thoughtcastLogo}
          width="450"
          height="101"
          alt="ThoughtCast"
          className="m-auto pt-24"
        />
        <ThinLine color="white" className="my-12 max-w-xl" />
        {!user.fbUser && (
          <p
            className="font-avenir-next font-semibold text-white text-xl text-center"
            style={{ lineHeight: '140%' }}
          >
            Thank you for joining Thoughtcast 2020! Please log in to watch a
            recording of the session.
          </p>
        )}

        {user.fbUser && !onDemandVideoURL && user.isAllowed && (
          <p
            className="font-avenir-next font-semibold text-white text-xl text-center"
            style={{ lineHeight: '140%' }}
          >
            Thank you for joining Thoughtcast // Future of Home Care! <br />
            The video of the event will be uploaded soon, please check back.
          </p>
        )}

        {(user.fbUser && onDemandVideoURL && user.isAllowed) ||
        (user.fbUser && onDemandVideoURL && user.isAdmin) ? (
          <div className="flex flex-col">
            <p
              className="font-avenir-next font-semibold text-white text-xl text-center mb-11"
              style={{ lineHeight: '140%' }}
            >
              Thank you for joining ThoughtCAST! Watch a recording of the
              session below.
            </p>
            <div className="flex mr-0 flex-col">
              <div className="relative pt-video-container md:p-0 md:h-post-event-video-desktop-h">
                <iframe
                  title="Video Viewer"
                  src={onDemandVideoURL}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  className="flex absolute w-full h-full top-0 left-0"
                  style={{
                    maxHeight: '432px',
                  }}
                />
              </div>
            </div>
            <p
              className="font-avenir-next font-semibold text-white text-xl text-center mt-6"
              style={{ lineHeight: '140%' }}
            >
              Confidential property of Kaiser Permanente
            </p>
          </div>
        ) : null}

        {user.fbUser && !user.isAllowed && !user.isAdmin && (
          <p
            className="font-avenir-next font-semibold text-white text-xl text-center"
            style={{ lineHeight: '140%' }}
          >
            This content cannot be accessed
          </p>
        )}
      </section>
      <SimpleUpcomingEvent />
      <Footer />
    </>
  )
}

export default PostEvent
