import React, { useEffect, createRef, useContext } from 'react'
import * as shared from 'pericles-shared'

import headerBg from 'assets/images/header-bg.jpg'
import kpLogo from 'assets/svg/kaiser-permanente-logo.svg'
import kpThoughtcastLogo from 'assets/svg/kp-thoughtcast-logo.svg'
import HeaderAreaContext from 'context/header-area'
import ModalContext from 'context/modal'
import usePresenceDetector from 'hooks/use-presence-detector'
import { useNavigate } from 'react-router-dom'
import getElementOffset from 'utils/get-element-offset'
import { ProfileIcon } from './icons'
import DropDownMenu, { DropDownMenuItem } from './form/drop-down-menu'
import LoginModalBody from './login-modal-body'
import Button from './button'

const { AuthContext } = shared

const NormalNavigation = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, ref) => {
  return (
    <nav
      ref={ref}
      className="flex justify-between flex-wrap p-6 bg-deep-blue absolute w-full items-baseline"
      style={{
        background: `url(${headerBg}) #003B71`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '658px',
      }}
    >
      {children}
    </nav>
  )
})

const SimpleNavigation = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(({ children }, ref) => {
  return (
    <nav
      ref={ref}
      className="flex justify-between flex-wrap px-3 py-6 w-full items-baseline"
    >
      {children}
    </nav>
  )
})

interface HeaderProps {
  variant?: 'normal' | 'simple'
}

const Header: React.FC<HeaderProps> = ({ variant = 'normal' }) => {
  const headerRef = createRef<HTMLElement>()
  const { elementReference } = useContext(HeaderAreaContext)
  const { showModal } = useContext(ModalContext)
  const navigate = useNavigate()
  usePresenceDetector()

  // Set the background height area to match the status container position
  useEffect(() => {
    const onResizeHandler = () => {
      if (headerRef.current && elementReference) {
        const elementOffset = getElementOffset(elementReference)
        headerRef.current.style.height = `${elementOffset.top}px`

        if (elementOffset.top === 0) {
          setTimeout(onResizeHandler, 100)
        }
      }
    }

    // Small interval for wait the elementReference be initially ready
    setTimeout(onResizeHandler, 10)

    window.addEventListener('resize', onResizeHandler)

    return () => {
      window.removeEventListener('resize', onResizeHandler)
    }
  }, [elementReference, headerRef])

  const userNotRegisteredHandler = () => {
    showModal({
      title: 'No registration found!',
      description: 'Please fill out a registration form in order to log in.',
      confirmText: 'OK',
    })
  }

  const emailDomainNotAllowedHandler = () => {
    showModal({
      title: 'Email not found!',
      description: 'Please log in with a Kaiser Permanente email address.',
      confirmText: 'OK',
    })
  }

  const handlerOnClickSignin = () => {
    showModal({
      showCloseButton: true,
      customBody: (
        <LoginModalBody
          onUserNotRegistered={userNotRegisteredHandler}
          onEmailDomainNotAllowed={emailDomainNotAllowedHandler}
        />
      ),
    })
  }

  const handlerOnClickSignout = async () => {
    navigate('/log_out')
  }

  const dropDownMenuLabel = (
    <button
      aria-label="Drop down"
      type="button"
      className="border border-white rounded-full focus:outline-none"
      style={{
        padding: '0.1rem 0.2rem',
        borderWidth: '3px',
      }}
    >
      <ProfileIcon width="18" height="22" className="fill-light-blue" />
    </button>
  )

  // Verify if user is logged in
  const user = AuthContext.useContainer()

  const headerContent = (
    <>
      {variant === 'simple' ? (
        <img
          src={kpThoughtcastLogo}
          alt="Kaiser Permanente Logo"
          width="298px"
          className="mb-5 md:mb-0"
        />
      ) : (
        <a
          href="https://about.kaiserpermanente.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={kpLogo} alt="Kaiser Permanente Logo" width="158px" />
        </a>
      )}
      {user ? (
        <DropDownMenu
          label={dropDownMenuLabel}
          className="bg-transparent text-lg shadow-dropdown cursor-pointer absolute z-20"
        >
          <DropDownMenuItem onClick={handlerOnClickSignout}>
            <span className="font-avenir-next font-semibold text-lg rounded-sm text-dolphin bg-white hover:text-inky focus:bg-mid-graphite px-4 py-dropdown-y-margin select-none">
              Sign out
            </span>
          </DropDownMenuItem>
        </DropDownMenu>
      ) : (
        <>
          <Button onClick={handlerOnClickSignin} variant="secondary">
            Log in
          </Button>
        </>
      )}
    </>
  )

  return (
    <>
      {variant === 'simple' ? (
        <SimpleNavigation ref={headerRef}>{headerContent}</SimpleNavigation>
      ) : (
        <NormalNavigation ref={headerRef}>{headerContent}</NormalNavigation>
      )}
    </>
  )
}

export default Header
