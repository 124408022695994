import React from 'react'

const LightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color } = props
  return (
    <svg
      {...props}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0001 56.3582C49.0373 56.3582 56.3634 49.0325 56.3634 39.9958C56.3634 30.959 49.0373 23.6333 40.0001 23.6333C30.9628 23.6333 23.6367 30.959 23.6367 39.9958C23.6367 49.0325 30.9628 56.3582 40.0001 56.3582Z"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0002 4V10.545"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 69.4536V75.9986"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5391 14.5469L19.1862 19.1938"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8164 60.812L65.4636 65.459"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 40.0005H10.5453"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4551 40.0005H76.0004"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5391 65.459L19.1862 60.812"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8164 19.1938L65.4636 14.5469"
        stroke={color ?? '#F36C0D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LightIcon
