import NicoArcino from 'assets/images/speakers/Nico Arcino.png'
import JodieLesh from 'assets/images/speakers/Jodie Lesh.png'
import TravisMessina from 'assets/images/speakers/Travis Messina.png'
import StephenParodi from 'assets/images/speakers/Stephen Parodi MD.png'
import GaryWaymire from 'assets/images/speakers/Gary Waymire.png'
import TomLee from 'assets/images/speakers/Tom Lee.png'
import SusanMagsamen from 'assets/images/speakers/Susan Magsamen.png'
import BJMiller from 'assets/images/speakers/BJ Miller.png'

interface SpeakerData {
  imageSource: string
  name: string
  jobTitle: string
  topic: string
}

const speakersList: SpeakerData[] = [
  {
    imageSource: JodieLesh,
    name: 'Jodie Lesh',
    jobTitle: 'SVP of Office of Transformation, Kaiser Permanente',
    topic: '',
  },
  {
    imageSource: StephenParodi,
    name: 'Stephen Parodi, MD',
    jobTitle:
      'Executive Vice President - External Affairs, Communications, and Brand, The Permanente Federation, Kaiser Permanente',
    topic: '',
  },
  {
    imageSource: BJMiller,
    name: 'BJ Miller, MD',
    jobTitle: 'Palliative Care Physician & Author',
    topic: '',
  },
  {
    imageSource: SusanMagsamen,
    name: 'Susan Magsamen',
    jobTitle:
      'Executive Director, International Arts and Mind Lab, Brain Science Institute, Johns Hopkins University',
    topic: '',
  },
  {
    imageSource: TomLee,
    name: 'Tom Lee, MD',
    jobTitle: 'CEO, Galileo Health',
    topic: '',
  },
  {
    imageSource: TravisMessina,
    name: 'Travis Messina',
    jobTitle: 'CEO, Contessa Health',
    topic: '',
  },
  {
    imageSource: GaryWaymire,
    name: 'Gary Waymire',
    jobTitle: 'Senior Director, Office of Transformation, Kaiser Permanente',
    topic: '',
  },
  {
    imageSource: NicoArcino,
    name: 'Nico Arcino',
    jobTitle: 'Head of Strategic Partnerships, Kaiser Permanente',
    topic: '',
  },
]

export default speakersList
