import React, { useEffect, useState } from 'react'
import * as shared from 'pericles-shared'
import { Navigate } from 'react-router-dom'
import usePresenceDetector from 'hooks/use-presence-detector'
import { USER_EMAIL_STORAGE_KEY } from '../../components/main-form-display/constants'

const LogOut = () => {
  const [processDone, setProcessDone] = useState(false)
  const { forceOffline } = usePresenceDetector()
  window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)

  useEffect(() => {
    const fetchLogOut = async () => {
      forceOffline()
      setTimeout(async () => {
        await shared.firebase.auth().signOut()
        setProcessDone(true)
      }, 1000)
    }
    fetchLogOut()
  }, [forceOffline])

  if (processDone) {
    return <Navigate to="/" />
  }
  return (
    <div className="flex w-screen h-screen flex-row justify-center items-center">
      <shared.Loader color="#FFF" />
    </div>
  )
}

export default LogOut
