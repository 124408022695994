import React from 'react'
import * as shared from 'pericles-shared'

const PreloaderStatus: React.FC = () => (
  <div className="bg-pebble w-full flex flex-col justify-between items-center pb-12 pt-12 shadow-dropdown rounded-b-lg">
    <shared.Loader />
  </div>
)

export default PreloaderStatus
