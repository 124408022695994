import React, { useContext, useEffect, memo, useCallback } from 'react'

import ChatContext, { ChatProvider } from 'context/chat'
import TopTabMenu from './top-tab-menu'
import { AdditionalTapButton } from './tab-button'
import DefaultTopTabMenuValues from './default-top-tab-menu-values'
import LiveChat from './contents/live-chat'
import QAndA from './contents/q-and-a'
import ActiveUsers from './contents/active-users'

interface ChatProps {
  additionalButtons?: AdditionalTapButton[]
  /**
   * On click over some button from the Top Tab Menu
   */
  onClickMenuButton?: (buttonValue: string) => void
}

const ChatContent = memo<ChatProps>(
  ({ additionalButtons, onClickMenuButton }) => {
    // Used to handle with global chat events and props. Destructuring isn't being used to have better readability
    const chatContext = useContext(ChatContext)

    useEffect(() => {
      if (onClickMenuButton) {
        // On click menu button handler
        chatContext.addClickMenuButtonHandler(onClickMenuButton)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      // Additional top tab menu buttons
      if (additionalButtons) {
        chatContext.setAdditionalButtons(additionalButtons)
        const defaultValues = Object.values(DefaultTopTabMenuValues)

        // Set default tab option if the current activated tab is not a default (The About for example)
        if (
          additionalButtons.length < 1 &&
          chatContext.lastClickedButtonValue &&
          defaultValues.indexOf(
            chatContext.lastClickedButtonValue as DefaultTopTabMenuValues
          ) < 0
        ) {
          chatContext.onClickMenuButton(DefaultTopTabMenuValues.Default)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalButtons])

    // Content provided by additional buttons
    const additionalContent = useCallback((): JSX.Element | undefined => {
      if (
        chatContext.additionalButtons &&
        chatContext.additionalButtons.length > 0
      ) {
        const buttonContent = chatContext.additionalButtons.find(
          (button) => button.value === chatContext.lastClickedButtonValue
        )
        return buttonContent?.content
      }

      return undefined
    }, [chatContext.additionalButtons, chatContext.lastClickedButtonValue])()

    const mainContent = useCallback((): JSX.Element | undefined => {
      if (!additionalContent) {
        switch (chatContext.lastClickedButtonValue) {
          case DefaultTopTabMenuValues.LiveChat:
            return <LiveChat />
          case DefaultTopTabMenuValues.QAndA:
            return <QAndA />
          case DefaultTopTabMenuValues.ActiveUsers:
            return <ActiveUsers />
          default:
        }
      }
      return undefined
    }, [additionalContent, chatContext.lastClickedButtonValue])()

    // Hide chat if it isn't enabled
    useEffect(() => {
      const validateChatDisposition = () => {
        if (
          !additionalContent &&
          !chatContext.isEnabled &&
          chatContext.lastClickedButtonValue ===
            DefaultTopTabMenuValues.LiveChat
        ) {
          chatContext.onClickMenuButton(DefaultTopTabMenuValues.QAndA)
        }
      }

      if (chatContext.lastClickedButtonValue) {
        validateChatDisposition()
      } else {
        chatContext.onClickMenuButton(DefaultTopTabMenuValues.QAndA)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      additionalContent,
      chatContext.lastClickedButtonValue,
      chatContext.isEnabled,
    ])

    return (
      <>
        <div className="flex flex-col bg-light-graphite h-full xl:rounded-r-lg xl:rounded-l-lg">
          <TopTabMenu />
          {mainContent && (
            <div className="h-chat-h-mobile xl:w-chat-tab-w xl:h-chat-h-desktop">
              {mainContent}
            </div>
          )}
        </div>
        {additionalContent}
      </>
    )
  }
)

const Chat: React.FC<ChatProps> = (props) => {
  return (
    <ChatProvider>
      <ChatContent {...props} />
    </ChatProvider>
  )
}

export default Chat
