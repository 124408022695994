import {
  ChatState,
  ChatReducer,
  CLICK_MENU_BUTTON_HANDLER_SET,
  SetClickMenuButtonHandlerPayload,
  OnClickMenuButtonPayload,
  MENU_BUTTON_CLICKED,
  ADDITIONAL_BUTTONS_ADDED,
  AddAdditionalButtonsPayload,
  SET_STREAM_CHAT_USER_PROPS,
  SetChatUserPropsPayload,
  UpdateOnlineCounterPayload,
  UPDATE_ONLINE_COUNTER,
  SET_IS_CHAT_ENABLED,
  SetIsChatEnabledPayload,
} from './types'

export const initialState: ChatState = {
  onlineCounter: 0,
  additionalButtons: [],
  isEnabled: false,
}

const setClickMenuButtonHandlerCase = (
  state: ChatState,
  payload: SetClickMenuButtonHandlerPayload
): ChatState => {
  const { onClickMenuButtonHandler } = payload
  return {
    ...state,
    onClickMenuButtonHandler,
  }
}

const onClickMenuButtonCase = (
  state: ChatState,
  payload: OnClickMenuButtonPayload
): ChatState => {
  const { buttonValue } = payload
  const { onClickMenuButtonHandler } = state

  // Dispatch
  if (onClickMenuButtonHandler) {
    onClickMenuButtonHandler(buttonValue)
  }
  return {
    ...state,
    lastClickedButtonValue: buttonValue,
  }
}

const addAdditionalButtonsCase = (
  state: ChatState,
  payload: AddAdditionalButtonsPayload
): ChatState => {
  const { additionalButtons } = payload
  return {
    ...state,
    additionalButtons,
  }
}

const setChatUserPropsCase = (
  state: ChatState,
  payload: SetChatUserPropsPayload
): ChatState => {
  return {
    ...state,
    ...payload,
  }
}

const updateOnlineCounterCase = (
  state: ChatState,
  payload: UpdateOnlineCounterPayload
): ChatState => {
  const { onlineCounter } = payload
  return {
    ...state,
    onlineCounter,
  }
}

const setIsChatEnabledCase = (
  state: ChatState,
  payload: SetIsChatEnabledPayload
): ChatState => {
  const { isEnabled } = payload
  return {
    ...state,
    isEnabled,
  }
}

const chatReducer: ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLICK_MENU_BUTTON_HANDLER_SET:
      return setClickMenuButtonHandlerCase(state, action.payload)
    case MENU_BUTTON_CLICKED:
      return onClickMenuButtonCase(state, action.payload)
    case ADDITIONAL_BUTTONS_ADDED:
      return addAdditionalButtonsCase(state, action.payload)
    case SET_STREAM_CHAT_USER_PROPS:
      return setChatUserPropsCase(state, action.payload)
    case UPDATE_ONLINE_COUNTER:
      return updateOnlineCounterCase(state, action.payload)
    case SET_IS_CHAT_ENABLED:
      return setIsChatEnabledCase(state, action.payload)
    default:
      return state
  }
}

export default chatReducer
