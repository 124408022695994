import React, { useState, useContext } from 'react'
import * as shared from 'pericles-shared'

import SignInContext from 'context/sign-in'
import { Input, Submit, InputFormatTypes, patterns } from 'components/form'
import sendSignInMagicLink from 'utils/auth/send-sign-in-magic-link'
import CurrentEventContext from 'context/event'
import { checkRegistration } from 'api/registration'
import useIsAllowedDomain from 'hooks/use-is-allowed-domain'
import { USER_EMAIL_STORAGE_KEY } from './main-form-display/constants'

interface LoginModalBodyProps {
  hideCopy?: boolean
  onUserNotRegistered?: () => void
  onEmailDomainNotAllowed?: () => void
}

const { errorHandler } = shared

const LoginModalBody: React.FC<LoginModalBodyProps> = ({
  hideCopy,
  onUserNotRegistered,
  onEmailDomainNotAllowed,
}) => {
  const [email, setEmail] = useState('')
  const isEmailDomainAllowed = useIsAllowedDomain(email)
  const [userRegisterStatus, setUserRegisterStatus] = useState<
    'not-registered' | 'pending' | 'error'
  >('pending')
  const [requestStatus, setRequestStatus] = useState<
    'pending' | 'in_progress' | 'complete' | 'error'
  >('pending')
  const { setUserEmail } = useContext(SignInContext)
  const eventId = CurrentEventContext.useContainer()

  let emailInputRef: React.RefObject<HTMLInputElement>

  const sendSignInLink = async () => {
    sendSignInMagicLink(email, 'login')
      .then(() => {
        setRequestStatus('complete')
      })
      .catch(() => {
        setRequestStatus('error')
        setUserRegisterStatus('error')
      })
  }

  const handlerOnRegister = async (
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    if (event) {
      event.preventDefault()
    }

    if (emailInputRef.current && !emailInputRef.current?.checkValidity()) {
      emailInputRef.current.focus()
      emailInputRef.current.blur()
    }

    if (isEmailDomainAllowed) {
      window.localStorage.setItem(USER_EMAIL_STORAGE_KEY, email)
      setUserEmail(email)

      if (email) {
        setRequestStatus('in_progress')

        try {
          const { isRegistered } = await checkRegistration({
            eventId: eventId!.id,
            userEmail: email,
          })

          if (onUserNotRegistered) {
            if (isRegistered) {
              sendSignInLink()
            } else {
              onUserNotRegistered()
            }
          } else {
            sendSignInLink()
          }
        } catch (error) {
          errorHandler(error)
        }
      }
    } else if (onEmailDomainNotAllowed) {
      onEmailDomainNotAllowed()
    }
  }

  return (
    <>
      {requestStatus === 'pending' && (
        <>
          {!hideCopy ? (
            <h4 className="font-avenir-next text-inky text-sm text-center font-normal my-6">
              Log into the event with your email
            </h4>
          ) : (
            <></>
          )}

          <form
            onSubmit={handlerOnRegister}
            className="flex flex-col w-full sm:w-auto sm:flex-row"
            noValidate
          >
            <Input
              type="email"
              id="email"
              placeholder="Email*"
              label="Email"
              defaultValue={email}
              forceErrorState={userRegisterStatus === 'not-registered'}
              errorMessage={
                userRegisterStatus === 'not-registered'
                  ? 'Sorry, we could not find your email in our records.'
                  : 'Please enter a valid email address'
              }
              pattern={patterns.emailPattern}
              required
              group={<Submit value="Login" />}
              inputFormat={InputFormatTypes.Rounded}
              onValidValue={(emailValue) => {
                setUserRegisterStatus('pending')
                setEmail(emailValue)
              }}
              onInvalidValue={() => setEmail('')}
              getRef={(ref) => {
                emailInputRef = ref
              }}
            />
          </form>
        </>
      )}
      {requestStatus === 'in_progress' && (
        <div className="w-full flex flex-col justify-between items-center mt-8">
          <shared.Loader />
        </div>
      )}
      {requestStatus === 'complete' && (
        <p className="font-avenir-next text-inky text-lg text-center mt-6 mx-4">
          Please check your email for confirmation to complete the login
          process.
        </p>
      )}

      {requestStatus === 'error' && userRegisterStatus === 'error' && (
        <>
          <h4 className="font-avenir-next text-inky text-sm text-center font-normal my-6">
            Something went wrong.
          </h4>
          <p className="font-avenir-next text-inky text-lg text-center mx-4">
            There is an error with the server request. Try again in a few
            minutes!
          </p>
        </>
      )}
    </>
  )
}

export default LoginModalBody
