import React, { createRef, useState, useCallback, useEffect } from 'react'

import Group from './group'
import InputFormatTypes from './input-format-types'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  inputFormat?: InputFormatTypes
  errorMessage?: string
  errorColor?: string
  group?: React.ReactNode
  forceErrorState?: boolean
  onValidValue?: (value: string) => void
  onInvalidValue?: () => void
  getRef?: (ref: React.RefObject<HTMLInputElement>) => void
}

const Input: React.FC<InputProps> = (props) => {
  const {
    type,
    id,
    name,
    pattern,
    required,
    label,
    placeholder,
    group,
    errorMessage,
    disabled,
    defaultValue,
    value,
    forceErrorState,
    errorColor,
    onBlur,
    onValidValue,
    onInvalidValue,
    onChange,
    getRef,
    inputFormat = InputFormatTypes.Normal,
  } = props
  const [isValid, setIsValid] = useState(true)
  const [hasValue, setHasValue] = useState(defaultValue != null)
  const reference = createRef<HTMLInputElement>()
  const inputStyle =
    inputFormat === InputFormatTypes.Normal
      ? 'bg-white w-full placeholder-dolphin pl-0 disabled:text-opacity-50 text-lg border-t-0 border-l-0 border-r-0 pb-normal-input-error-mb outline-none focus:border-blue'
      : 'bg-white w-11/12 placeholder-dolphin sm:w-rounded-input-w h-rounded-input-h rounded-l-lg rounded-r-lg px-6 disabled:text-opacity-50 text-lg outline-none focus:border-blue'

  const handlerOnBlur = useCallback(
    (event) => {
      if (!reference.current?.checkValidity()) {
        setIsValid(false)
        if (onInvalidValue) {
          onInvalidValue()
        }
      } else {
        setIsValid(true)
        if (onValidValue) {
          onValidValue(reference.current?.value)
        }
      }

      if (onBlur) {
        onBlur(event)
      }
    },
    [onBlur, onInvalidValue, onValidValue, reference]
  )

  const handlerKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handlerOnBlur(event)
      }
    },
    [handlerOnBlur]
  )

  const handlerOnChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event)
      }
      if (reference.current && reference.current?.value.length > 0) {
        setHasValue(true)
      } else {
        setHasValue(false)
      }

      if (!isValid && !reference.current?.checkValidity()) {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    },
    [isValid, onChange, reference]
  )

  useEffect(() => {
    if (getRef) {
      getRef(reference)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference])

  const error = (
    <p
      className={`text-red text-xs mt-2 font-avenir-next ${
        inputFormat === InputFormatTypes.Normal ? 'mt-px' : 'mt-base-margin'
      }`}
      style={{
        color: errorColor,
      }}
    >
      {errorMessage}
    </p>
  )

  return (
    <div
      className={`flex flex-col ${
        inputFormat === InputFormatTypes.Normal ? 'mb-normal-input-mb' : 'mb-4'
      }`}
    >
      {inputFormat === InputFormatTypes.Normal && hasValue ? (
        <label
          htmlFor={id}
          className="text-xs mb-px animation-label-show animation-fill-forwards animation-once animation-0.07s z-10"
        >
          {label}
        </label>
      ) : null}
      <Group>
        <input
          aria-label={label ?? placeholder}
          placeholder={placeholder}
          type={type}
          id={id}
          name={name}
          pattern={pattern}
          required={required}
          ref={reference}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          onBlur={handlerOnBlur}
          onKeyDown={handlerKeyDown}
          onChange={handlerOnChange}
          className={`${group != null ? 'sm:mr-base-margin' : ''} ${!isValid &&
            'border-red'} ${inputStyle} ${forceErrorState && 'border-red'}`}
        />
        <div className="sm:hidden">
          {forceErrorState ? (
            error
          ) : (
            <>{!isValid && error != null ? error : null}</>
          )}
        </div>
        {group && <div className="mt-4 sm:mt-0">{group}</div>}
      </Group>
      <div className="hidden sm:block">
        {forceErrorState ? (
          error
        ) : (
          <>{!isValid && error != null ? error : null}</>
        )}
      </div>
    </div>
  )
}

export default Input
