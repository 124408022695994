import { createSingleton } from 'reactive-singleton'

import * as types from 'pericles-types'
import { checkWhitelistAccessStatus } from 'api/auth'
import {
  isInitialRegistration,
  checkRegistration,
  getBasicUserInfo,
} from 'api/registration'

import { firebase, errorHandler } from 'pericles-shared'
import { USER_EMAIL_STORAGE_KEY } from 'components/main-form-display/constants'

export interface User {
  fbUser?: firebase.User | null
  isAdmin: boolean | null
  firstName?: string | null
  lastName?: string | null
  isAllowed?: boolean | null
  isInWhitelist?: boolean | null
  isRestricted?: boolean | null
  hasRequestedAccess?: boolean | null
  isRegistered?: boolean | null
  infoPending: boolean

  hasError: boolean | null
  userFetchError: boolean | null
  accessFetchError: boolean | null
  registrationFetchError: boolean | null
  basicUserInfoError: boolean | null
  attendLiveEvent: boolean | null
  /** Determines if its the first time the user registered */
  initialRegistration: boolean | null
}

const { useWatcher, setValue } = createSingleton()

const ONE_DAY_IN_MS = 86400000

class UserService {
  private static instance: UserService

  private accessRequestPending: boolean

  private registrationRequestPeding: boolean

  private basicInfoRequestPending: boolean

  private requestAccessDataComplete = false

  private requestBasicUserDataComplete = false

  private requestRegistrationDataComplete = false

  public user: User = {
    fbUser: null,
    isAdmin: false,
    firstName: null,
    lastName: null,
    isAllowed: null,
    isInWhitelist: null,
    isRestricted: null,
    hasRequestedAccess: null,
    isRegistered: null,
    infoPending: true,
    // Error states
    hasError: null,
    userFetchError: null,
    accessFetchError: null,
    registrationFetchError: null,
    basicUserInfoError: null,
    attendLiveEvent: null,
    initialRegistration: null,
  }

  private constructor() {
    this.accessRequestPending = true
    this.registrationRequestPeding = true
    this.basicInfoRequestPending = true

    this.user.fbUser = null
    this.user.isAllowed = false
    this.user.isInWhitelist = false
    this.user.isRestricted = true
    this.user.isRegistered = false
    this.user.hasRequestedAccess = false
    this.user.attendLiveEvent = false

    this.requestAccessDataComplete = false
    this.requestBasicUserDataComplete = false
    this.requestRegistrationDataComplete = false

    useWatcher((done) => {
      try {
        firebase
          .auth()
          /* eslint-disable @typescript-eslint/no-explicit-any */
          .onAuthStateChanged(async (user: any) => {
            if (user) {
              /* eslint-disable @typescript-eslint/no-explicit-any */
              user.getIdTokenResult().then(async (idTokenResult: any) => {
                if (firebase.auth().currentUser) {
                  const authTime = new Date(idTokenResult.authTime).getTime()
                  const nowTime = new Date().getTime()
                  if (nowTime - authTime >= ONE_DAY_IN_MS) {
                    window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
                    await firebase.auth().signOut()
                  }
                }

                /* eslint-disable no-param-reassign */
                user.admin = idTokenResult.claims.admin
                this.user.isAdmin = idTokenResult.claims.admin
              })
            }
            this.user.fbUser = user
            await this.checkOldLoginData()
            this.updateAccessData()
          })
      } catch (error) {
        errorHandler(error)
        this.user.fbUser = null
        done()
      }
    })
  }

  private async checkOldLoginData() {
    if (firebase.auth().currentUser) {
      try {
        const result = await checkRegistration({
          eventId: 'event2',
          userEmail: this.user!.fbUser!.email!,
        })
        if (!result.isRegistered) {
          window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)
          await firebase.auth().signOut()
          window.location.reload()
        }
      } catch (error) {
        errorHandler(error, {
          others: { where: 'user-service.ts > checkOldLoginData()' },
        })
      }
    }
  }

  private checkUpdateAccessData() {
    useWatcher((done) => {
      if (
        this.requestAccessDataComplete &&
        this.requestBasicUserDataComplete &&
        this.requestRegistrationDataComplete
      ) {
        done()
      }
    })
  }

  public updateAccessData() {
    this.requestAccessDataComplete = false
    this.requestBasicUserDataComplete = false
    this.requestRegistrationDataComplete = false

    useWatcher(() => {
      if (this.user.fbUser) {
        this.getAccessData()!
          .then((data) => {
            this.user.isInWhitelist = data.existsInWhitelist
            this.user.isRestricted = data.restrictAccess
            this.user.hasRequestedAccess = data.requestAccess
            this.user.isAllowed = data.existsInWhitelist && !data.restrictAccess
            this.accessRequestPending = false
            this.checkPending()
            this.requestAccessDataComplete = true
            this.checkUpdateAccessData()
          })
          .catch((error) => {
            errorHandler(error)
            this.user.accessFetchError = true
            this.user.hasError = true
            this.requestAccessDataComplete = true
            this.checkUpdateAccessData()
          })

        // Check registration
        this.getRegistrationStatus()!
          .then((data) => {
            this.user.isRegistered = data.isRegistered
            this.registrationRequestPeding = false
            this.checkPending()
            this.requestRegistrationDataComplete = true
            this.checkUpdateAccessData()
          })
          .catch((error) => {
            errorHandler(error)
            this.user.registrationFetchError = true
            this.user.hasError = true
            this.requestRegistrationDataComplete = true
            this.checkUpdateAccessData()
          })

        this.getBasicUserData()!
          .then((data) => {
            this.user.firstName = data.firstName
            this.user.lastName = data.lastName
            this.basicInfoRequestPending = false
            this.checkPending()
            this.requestBasicUserDataComplete = true
            this.checkUpdateAccessData()
          })
          .catch((error) => {
            errorHandler(error)
            this.user.basicUserInfoError = true
            this.user.hasError = true
            this.requestBasicUserDataComplete = true
            this.checkUpdateAccessData()
          })

        this.checkIfInitialRegistration()!
          .then((data) => {
            this.user.initialRegistration = data.isInitialRegistration
          })
          .catch((error) => {
            errorHandler(error)
            this.user.hasError = true
          })
      } else {
        this.requestAccessDataComplete = true
        this.requestBasicUserDataComplete = true
        this.requestRegistrationDataComplete = true
        this.checkUpdateAccessData()
      }
    })
  }

  private getBasicUserData(): Promise<types.GetBasicUserInfoResponse> | null {
    if (!this.user.fbUser) return null
    const res = this.user.fbUser.getIdToken().then((data) => {
      return getBasicUserInfo({
        userEmail: this.user.fbUser?.email as string,
        accessToken: data,
      })
    })
    return res
  }

  private checkIfInitialRegistration = (): Promise<
    types.IsInitialRegistrationResponse
  > | null => {
    if (!this.user.fbUser) return null
    return isInitialRegistration({
      email: this.user!.fbUser!.email!,
      eventId: 'event2',
    })
  }

  private checkPending() {
    useWatcher((done) => {
      if (
        !this.accessRequestPending &&
        !this.registrationRequestPeding &&
        !this.basicInfoRequestPending
      ) {
        this.user.infoPending = false
      }
      done()
    })
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService()
    }
    return UserService.instance
  }

  public setAttendEvent = (): void => {
    useWatcher((done) => {
      this.user.attendLiveEvent = true
      done()
    })
  }

  private getAccessData(): Promise<
    types.CheckWhitelistAccessStatusResponse
  > | null {
    if (!this.user.fbUser) return null
    return checkWhitelistAccessStatus({
      eventId: 'event2',
      email: this.user!.fbUser!.email!,
    })
  }

  private getRegistrationStatus(): Promise<
    types.CheckRegistrationResponse
  > | null {
    if (!this.user.fbUser) return null
    return checkRegistration({
      eventId: 'event2',
      userEmail: this.user!.fbUser!.email!,
    })
  }
}
setValue(UserService)

export default UserService
