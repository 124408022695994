import React from 'react'
import kpThoughtcastLogo from 'assets/svg/kp-thoughtcast-logo.svg'

const TechnicalDifficulties: React.FC = () => {
  return (
    <div className="flex flex-col absolute w-full h-full top-0 left-0 justify-center items-center font-avenir-next text-center">
      <h1 className="text-5xl mb-8">Please stand by</h1>
      <p className="text-xl">
        We are solving the issue and will return momentarily.
      </p>
      <p className="text-xl mb-10">Thank you for your patience.</p>
      <img src={kpThoughtcastLogo} alt="Thoughtcast Logo" className="w-2/5" />
    </div>
  )
}

export default TechnicalDifficulties
