import React from 'react'
import RoundedBottomContainer from './rounded-bottom-container'

const RegistrationEmailSentStatus: React.FC = () => (
  <RoundedBottomContainer>
    <p className="px-0 sm:px-12 text-xl">
      Please check your inbox to validate your email address & complete the
      registration process. If you do not receive a message to verify your email
      address within 15 minutes, please try submitting the form again.
    </p>
  </RoundedBottomContainer>
)

export default RegistrationEmailSentStatus
