import React from 'react'
import GradientText from 'components/gradient-text'
import SpeakerTile from './speaker-tile'
import SpeakerSectionTypes from './speaker-section-types'

import speakersListData from './speakers-data'

interface SpeakersSectionProps {
  sectionType: SpeakerSectionTypes
}

const SpeakersSection: React.FC<SpeakersSectionProps> = ({ sectionType }) => {
  const speakers = speakersListData.map(
    ({ imageSource, name, jobTitle, topic }) => (
      <SpeakerTile
        key={`speaker-${name}`}
        sectionType={sectionType}
        imageSource={imageSource}
        name={name}
        jobTitle={jobTitle}
        topic={topic}
      />
    )
  )

  if (sectionType === SpeakerSectionTypes.Landing) {
    return (
      <div className="flex flex-col px-speakers-padding-x py-speakers-padding-y w-full background-image-secondary-background">
        <GradientText
          variant="secondary"
          className="mb-12 text-4xl font-semibold self-center"
        >
          Speakers
        </GradientText>
        <div className="flex flex-wrap justify-center">{speakers}</div>
      </div>
    )
  }

  const showExtraEmptySpeaker = speakers.length % 3 > 0

  return (
    <div className="flex flex-col p-8 w-full background-image-secondary-background">
      <div
        className="flex flex-wrap justify-between max-w-6xl 2xl:justify-start 2xl:justify-between 3xl:justify-left 3xl:max-w-full m-auto"
        style={{ maxWidth: 1200 }}
      >
        <h4
          className="text-white not-italic font-semibold text-4xl mb-12 font-avenir-next"
          style={{ flex: '0 0 100%' }}
        >
          Speakers
        </h4>
        {speakers}
        {showExtraEmptySpeaker ? (
          <div className="w-speaker-tile-area hide xl:block" />
        ) : null}
      </div>
    </div>
  )
}

export default SpeakersSection
