import React from 'react'

const ProfileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 18 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1429 5.33345C14.1429 8.27485 11.8363 10.6669 9 10.6669C6.16371 10.6669 3.85714 8.27485 3.85714 5.33345C3.85714 2.39205 6.16371 0 9 0C11.8363 0 14.1429 2.39205 14.1429 5.33345ZM11.571 5.33397C11.571 3.86327 10.4177 2.66725 8.99956 2.66725C7.58142 2.66725 6.42813 3.86327 6.42813 5.33397C6.42813 6.80467 7.58142 8.00069 8.99956 8.00069C10.4177 8.00069 11.571 6.80467 11.571 5.33397Z"
        fill={props?.fill ?? 'white'}
      />
      <path
        d="M0 22.6666C0 17.5199 4.03843 13.3331 9 13.3331C13.9616 13.3331 18 17.5199 18 22.6666C18 23.4027 17.4253 24 16.7143 24C16.0033 24 15.4286 23.4027 15.4286 22.6666C15.4286 18.9906 12.5447 15.9998 9 15.9998C5.45529 15.9998 2.57143 18.9906 2.57143 22.6666C2.57143 23.4027 1.99671 24 1.28571 24C0.574714 24 0 23.4027 0 22.6666Z"
        fill={props?.fill ?? 'white'}
      />
    </svg>
  )
}

export default ProfileIcon
