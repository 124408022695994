import React, { createRef, useState, useEffect } from 'react'

import selectArrow from 'assets/svg/select-arrow.svg'

type SelectOptionsProps = {
  label: string
  value?: string
}

type SelectProps = {
  label: string
  selectOptions: SelectOptionsProps[]
  id: string
  onValidValue?: (value: string) => void
}

const Select: React.FC<SelectProps> = (props) => {
  const { id, label, selectOptions, onValidValue } = props
  const [
    selectedOption,
    setSelectedOption,
  ] = useState<SelectOptionsProps | null>()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownReference = createRef<HTMLDivElement>()

  const handlerOnSelect = (selectedOptionProps: SelectOptionsProps) => {
    setIsOpen(false)
    setSelectedOption(selectedOptionProps)
    if (onValidValue) {
      onValidValue(selectedOptionProps?.value ?? '')
    }
  }

  // Detects whether the user clicked outside
  useEffect(() => {
    const handlerOnMouseDown = (event: MouseEvent) => {
      if (!dropdownReference.current?.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handlerOnMouseDown)
    return () => {
      document.removeEventListener('mousedown', handlerOnMouseDown)
    }
  }, [dropdownReference])

  return (
    <div
      ref={dropdownReference}
      className="flex flex-col mb-16 mr-6 w-full"
      style={{
        background: `url(${selectArrow}) no-repeat`,
        backgroundPosition: selectedOption != null ? '98% 28px' : '98% 4px',
      }}
    >
      <div onClickCapture={() => setIsOpen(true)}>
        {selectedOption != null ? (
          <label
            htmlFor={id}
            className="mb-px text-lg animation-label-show animation-fill-forwards animation-once animation-0.07s z-10"
          >
            {label}
          </label>
        ) : (
          <label htmlFor={id} className="block w-4/5 mb-px text-lg">
            {label}
          </label>
        )}
      </div>
      <p
        onClickCapture={() => setIsOpen(true)}
        className={`font-avenir-next text-inky text-lg border border-dolphin border-t-0 border-l-0 border-r-0 pb-normal-input-error-mb select-none ${
          isOpen ? 'border-none' : ''
        }`}
      >
        {selectedOption != null ? selectedOption.label : null}
      </p>
      <ul
        className={`font-avenir-next bg-white text-lg shadow-dropdown${
          !isOpen ? ' hidden' : ''
        }`}
      >
        {selectOptions.map((option) => (
          <li
            key={option.value}
            onClickCapture={() => handlerOnSelect(option)}
            className="font-avenir-next text-lg text-inky hover:bg-mid-graphite px-4 py-dropdown-y-margin select-none"
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
