import React from 'react'

const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 15.3684L28 4L16.6316 28L14.1053 17.8947L4 15.3684Z"
        fill="#0078B3"
        stroke="#0078B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SendIcon
