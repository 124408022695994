import React from 'react'
import { Link } from 'react-router-dom'

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to?: string
}

const SimpleLink: React.FC<LinkProps> = (props) => {
  const { children, className, to } = props
  return (
    <>
      {to ? (
        <Link
          to={to}
          className={`font-avenir-next font-semibold text-base text-white ${className}`}
        >
          {children}
        </Link>
      ) : (
        <a
          {...props}
          className={`font-avenir-next font-semibold text-base text-white ${className}`}
        >
          {children}
        </a>
      )}
    </>
  )
}

export default SimpleLink
