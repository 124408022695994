import React from 'react'
import DocumentLayout from 'layouts/document-layout'
import {
  Paragraph,
  Subtitle,
  List,
  ListItem,
  Info,
  Anchor,
} from 'components/document'

const TermsOfUse: React.FC = () => {
  const textContent = (
    <>
      <Subtitle>Purpose</Subtitle>
      <Paragraph>
        Kaiser Permanente provides the ThoughtCAST internet-based event platform
        in partnership with Left Field Labs. This platform allows users to
        register for and participate in a live webcast event, use of chat
        functionality and to provide responses to polls.
      </Paragraph>

      <Subtitle>Agreement</Subtitle>
      <Paragraph>
        BY USING THE WEBSITE YOU SIGNIFY YOUR AGREEMENT TO THESE TERMS AND
        CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT
        USE THE WEBSITE.
      </Paragraph>

      <Subtitle>Breaches of these Terms and Conditions</Subtitle>
      <Paragraph>
        In consideration of being allowed to use the Websites&apos; interactive
        services, you agree that the following actions shall constitute a
        material breach of these Terms and Conditions:
      </Paragraph>
      <List>
        <ListItem>signing on as or pretending to be another person </ListItem>
        <ListItem>
          using chat messaging for any purpose in violation of local, state,
          national, international laws or posted Kaiser Permanente policies
        </ListItem>
        <ListItem>
          transmitting material that infringes or violates the intellectual
          property rights of others or the privacy or publicity rights of others
        </ListItem>
        <ListItem>
          transmitting material that is unlawful, obscene, defamatory, predatory
          of minors, threatening, harassing, abusive, slanderous, or hateful to
          any person (including Kaiser Permanente personnel) or entity as
          determined by Kaiser Permanente in its sole discretion
        </ListItem>
        <ListItem>
          using interactive services in a way that is intended to harm, or a
          reasonable person would understand would likely result in harm, to the
          user or others
        </ListItem>
        <ListItem>
          collecting information about others, including email addresses
        </ListItem>
        <ListItem>
          intentionally distributing viruses or other harmful computer code
        </ListItem>
        <ListItem>
          attempting to (1) probe, scan, &quot;hack&quot;, or test the
          vulnerability of the Website, the KP Mobile Application or any Kaiser
          Permanente system or network; or (2) breach any security or
          authentication measures on the Website, KP Mobile Application or any
          Kaiser Permanente system connected to either the Website or KP Mobile
          Application using any &quot;deep-link&quot;, &quot;page-scrape&quot;,
          &quot;robot&quot;, &quot;spider&quot;, data mining tools, data
          gathering and extraction tools, or other automatic device, program,
          algorithm or methodology, to (1) access, acquire, copy or monitor any
          portion of the Website or the KP Mobile Application, or (2) in any way
          reproduce or circumvent the navigational structure or presentation of
          the Website or the KP Mobile Application
        </ListItem>
      </List>
      <Paragraph>
        Kaiser Permanente expressly reserves the right, in its sole discretion,
        to terminate a user&apos;s access to any interactive services and/or to
        any or all other areas of the Websites due to any act that would
        constitute a violation of these Terms and Conditions. To the extent
        there is an inconsistency between these Terms and Conditions and the
        Website Privacy Statement, these Terms and Conditions shall govern.
      </Paragraph>

      <Subtitle>Use by children</Subtitle>
      <Paragraph>
        We do not knowingly allow Kaiser Permanente members under the age of 13
        to create accounts that allow access to the secured features of the
        Websites.
      </Paragraph>

      <Subtitle>Access, correction, and data integrity</Subtitle>
      <Paragraph>
        Although we attempt to maintain the integrity and accuracy of the
        information on the Website, we make no guarantees as to its correctness,
        completeness, or accuracy. The Website may contain typographical errors,
        inaccuracies, or other errors or omissions. Also, unauthorized
        additions, deletions, or alterations could be made to the Website by
        third parties without our knowledge. If you believe that information
        found on the Website is inaccurate or unauthorized, please inform us by
        contacting our Web Manager. (On our kp.org website, you may Contact Us
        using the link available at the bottom of each web page.)
      </Paragraph>

      <Subtitle>Revisions, changes, and updates</Subtitle>
      <Paragraph>
        We may revise the information on the Websites or otherwise change or
        update the Websites, including these Terms and Conditions, without
        notice to you. Kaiser Permanente may also make improvements and/or
        changes in products and/or services described on the Websites or add new
        features at any time without notice. We encourage you to periodically
        read these Terms and Conditions to see if there have been any changes to
        our policies that may affect you. Your continued use of the Website will
        signify your continued agreement to these Terms and Conditions as they
        may be revised.
      </Paragraph>

      <Subtitle>Links to Third Party Websites</Subtitle>
      <Paragraph>
        The Kaiser Permanente Website provides links to other websites that are
        not owned or controlled by Kaiser Permanente (“Third Party Websites”).
        We provide links to Third Party Websites as follows.
      </Paragraph>
      <List>
        <ListItem>
          Kaiser Permanente provides links to Third Party Websites to connect
          you easily to additional sources of health information or third-party
          services that may be of interest to you. We may not have any business
          relationship with the party that controls this type of Third-Party
          website and a link to such a site is offered only as a convenience to
          you.
        </ListItem>
        <ListItem>
          Kaiser Permanente also provides links to Third-Party Websites managed
          by vendors that we have made arrangements with to offer you services
          to help you manage your health or to take and fulfill orders when you
          purchase items or materials from us. These Third-Party Websites may be
          co-branded, meaning that they display the Kaiser Permanente logo and
          the logo of the Third-Party vendor but they are owned and controlled
          by the Third Party.
        </ListItem>
      </List>
      <Paragraph>
        In each such instance, where practicable, we will let you know when you
        are leaving the Website and linking to a Third-Party website. We may use
        an icon that we identify with an appropriate legend to let you know when
        you are leaving the Website.
      </Paragraph>
      <Paragraph>
        Kaiser Permanente is not responsible for the content, security or the
        privacy practices of Third-Party Websites. Please review the privacy
        statement and any terms of use for each Third-Party website you visit.
        Unless we specifically advise you otherwise, links to Third-Party
        Websites do not constitute or imply endorsement by Kaiser Permanente of
        those sites, the information they contain or any products or services
        they describe. Kaiser Permanente does not receive payment or other
        remuneration in exchange for you linking to or using any Third-Party
        Website.
      </Paragraph>

      <Subtitle>Disclaimer and Limitation of liability</Subtitle>
      <Paragraph>Disclaimer of Warranties</Paragraph>
      <Paragraph>
        THE WEBSITE, AND ANY CONTENT, INFORMATION, SERVICES OR PRODUCTS OBTAINED
        THROUGH THE WEBSITE ARE PROVIDED &quot;AS IS,&quot; WITH ALL FAULTS,
        WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
        YOUR USE OF THE WEBSITE IS VOLUNTARY, AND AT YOUR OWN RISK. ANY
        REFERENCES TO SPECIFIC PRODUCTS OR SERVICES ON THE WEBSITE DO NOT
        CONSTITUTE OR IMPLY A RECOMMENDATION OR ENDORSEMENT OF SUCH PRODUCTS OR
        SERVICES BY KAISER PERMANENTE UNLESS SPECIFICALLY STATED OTHERWISE.
      </Paragraph>
      <Paragraph>Limitation of liability</Paragraph>
      <Paragraph>
        KAISER PERMANENTE AND ITS AFFILIATES, SUPPLIERS, AND OTHER THIRD PARTIES
        MENTIONED OR LINKED TO ON THE WEBSITE ARE NEITHER RESPONSIBLE NOR LIABLE
        FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
        PUNITIVE, OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
        RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION)
        ARISING OUT OF OR RELATING IN ANY WAY TO THE WEBSITE, AND ANY CONTENT,
        INFORMATION, SERVICES OR PRODUCTS OBTAINED THROUGH THE WEBSITE, OR YOUR
        USE OF ANY OF THE FOREGOING, WHETHER BASED ON WARRANTY, CONTRACT, TORT,
        OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES. EXCEPT AS DESCRIBED IN THE FOLLOWING PARAGRAPH, YOUR
        SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE, SITE-RELATED SERVICES,
        IS TO STOP USING THE WEBSITE, AND/OR THOSE SERVICES.
      </Paragraph>
      <Paragraph>Choice of law</Paragraph>
      <Paragraph>
        THESE TERMS AND CONDITIONS ARE GOVERNED BY CALIFORNIA LAW WITHOUT REGARD
        TO ITS PRINCIPLES OF CONFLICTS OF LAW. IF ANY VERSION OF THE UNIFORM
        COMPUTER INFORMATION TRANSACTIONS ACT (UCITA) IS ENACTED AS PART OF THE
        LAW OF CALIFORNIA, THAT STATUTE SHALL NOT GOVERN ANY ASPECT OF THESE
        TERMS AND CONDITIONS.
      </Paragraph>

      <Subtitle>Copyrights</Subtitle>
      <Paragraph>
        Except as otherwise indicated, the KP Mobile Application and all content
        on the Websites, including text, graphics, logos, button icons, photos,
        images, forms, audio, video, questionnaires, and software, is the
        property of Kaiser Permanente or its licensors and is protected by
        United States and international copyright laws. Kaiser Permanente allows
        you to view or download a single copy of the material on the website
        solely for your personal, noncommercial use.
      </Paragraph>
      <Paragraph>
        The compilation of all content on the Websites is the exclusive property
        of Kaiser Permanente and is protected by United States and international
        copyright laws. Unless specifically authorized in writing by Kaiser
        Permanente, any use of these materials, or of any materials contributed
        to the Websites by entities other than Kaiser Permanente, on any other
        website or networked computer environment for any purpose is prohibited.
      </Paragraph>
      <Paragraph>
        Any rights not expressly granted by these Terms and Conditions or any
        applicable end-user license agreements are reserved by Kaiser
        Permanente. Content and features are subject to change or termination
        without notice in the editorial discretion of Kaiser Permanente.
      </Paragraph>
      <Paragraph>
        The Digital Millennium Copyright Act of 1998 (the &quot;DMCA&quot;)
        provides recourse for copyright owners who believe that material
        appearing on the Internet infringes their rights under U.S. copyright
        law. If you believe in good faith that materials appearing on the
        Websites or KP Mobile Application infringe your copyright, you (or your
        agent) may send us a notice requesting that the material be removed, or
        access to it blocked.
      </Paragraph>
      <Paragraph>
        In addition, if you believe in good faith that a notice of copyright
        infringement has been wrongly filed against you, the DMCA permits you to
        send us a counter-notice. Notices and counter-notices must meet
        statutory requirements imposed by the DMCA. One place to find more
        information is the U.S. Copyright Office website, currently located at{' '}
        <Anchor
          href="https://www.copyright.gov"
          target="blank_"
          className="text-mid-blue focus:text-deep-blue hover:text-deep-blue visited:text-mid-blue"
        >
          https://www.copyright.gov
        </Anchor>
        .
      </Paragraph>
      <Paragraph>
        In accordance with the DMCA, Kaiser Permanente has designated an agent
        to receive notification of alleged copyright infringement in accordance
        with the DMCA. Any written Notification of Claimed Infringement should
        comply with Title 17, United States Code, Section 512(c)(3)(A) and
        should be provided in writing to our designated agent as follows:
      </Paragraph>
      <Paragraph>
        Agent: Kaiser Permanente Strategic Partnerships Team <br />
        Email:
        <Anchor href="mailto:ThoughtCast@kp.org">ThoughtCast@kp.org</Anchor>
      </Paragraph>
      <Paragraph>
        (This telephone number is for copyright-related complaints only. No
        solicitations. For member and website services, call 800-556-7677.)
      </Paragraph>
      <Paragraph>
        Please note: If you materially misrepresent that online material,
        product, or activity is infringing your copyrights, you may be liable
        for damages (including court costs and attorneys&apos; fees) and could
        be subject to criminal prosecution for perjury. We suggest that you
        consult your legal advisor before filing a notice or counter-notice.
      </Paragraph>

      <Subtitle>Trademarks and service marks</Subtitle>
      <Paragraph>
        &quot;Kaiser Permanente&quot; and the Kaiser Permanente logo are
        registered service marks of Kaiser Foundation Health Plan, Inc. Other
        proprietary marks of Kaiser Permanente or third parties may be
        designated as such from time to time on the Websites or KP Mobile
        Application through use of the TM, SM, or ® symbols. Users of the
        Websites or KP Mobile Application are not authorized to make any use of
        the Kaiser Permanente marks or the proprietary marks of third parties,
        including but not limited to, as metatags or in any other fashion that
        may create a false or misleading impression of affiliation or
        sponsorship with or by Kaiser Permanente or the applicable third party.
      </Paragraph>
      <Info>
        Copyright © 1995 to 2019 Kaiser Permanente. All rights reserved for all
        countries.
      </Info>
      <Info>Last updated: July 1, 2020</Info>
      <Info>Version 1.0</Info>
    </>
  )
  return (
    <DocumentLayout
      title="Terms and Conditions for ThoughtCAST Event Platform"
      textElement={textContent}
    />
  )
}

export default TermsOfUse
