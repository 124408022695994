import React, { useState, useContext } from 'react'
import * as shared from 'pericles-shared'

import { Input, Submit, RadioInput, Select, patterns } from 'components/form'
import ModalContext from 'context/modal'
import GradientText from 'components/gradient-text'
import Button from 'components/button'
import SignInContext from 'context/sign-in'
import scrollToElement from 'utils/scroll-to-element'
import sendSignInMagicLink from 'utils/auth/send-sign-in-magic-link'
import * as registrationApi from 'api/registration'
import * as auth from 'api/auth'
import CurrentEventContext from 'context/event'
import { errorHandler } from 'pericles-shared'
import useIsAllowedDomain from 'hooks/use-is-allowed-domain'
import { USER_EMAIL_STORAGE_KEY } from '../constants'

interface RegisterFormProps {
  onStartRequest?: () => void
  onRequestComplete: () => void
  onRequestAccessAlreadySent: () => void
  onRegisteredAndNotInWhitelist: () => void
  onRequestError: (
    errorMessage: string,
    bottonRounded?: boolean,
    confirmButtonLabel?: string,
    onClickConfirm?: () => void
  ) => void
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  onStartRequest,
  onRequestComplete,
  onRequestError,
}) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isKaiserEmployee, setIsKaiserEmployee] = useState(true)
  const [company, setCompany] = useState('Kaiser Permanente')
  const [jobTitle, setJobTitle] = useState('')
  const [location, setLocation] = useState('')
  const [hearAbout, setHearAbout] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [requestStatus, setRequestStatus] = useState<
    'not_started' | 'pending' | 'in_progress' | 'complete'
  >('not_started')
  const [form, setForm] = useState<HTMLFormElement>()

  const [email, setEmail] = useState('')
  const isEmailDomainAllowed = useIsAllowedDomain(email)

  const { showModal } = useContext(ModalContext)
  const { setUserEmail } = useContext(SignInContext)
  const eventId = CurrentEventContext.useContainer()

  let emailInputRef: React.RefObject<HTMLInputElement>

  const showErrorModal = (error?: Error) => {
    showModal({
      title: 'Something went wrong',
      description:
        error?.message ??
        'There is an error with the server request. Try again in a few minutes!',
      confirmText: 'Try Again',
      onConfirm: () => {
        window.location.reload()
      },
    })
  }

  const handlerOnRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    scrollToElement('statusContainer')
    setRequestStatus('in_progress')
    if (emailInputRef.current && !emailInputRef.current?.checkValidity()) {
      emailInputRef.current.focus()
      emailInputRef.current.blur()
    }

    window.localStorage.setItem(USER_EMAIL_STORAGE_KEY, email)
    setUserEmail(email)

    if (email) {
      setRequestStatus('in_progress')

      if (!isEmailDomainAllowed) {
        try {
          await auth.requestAccessToWhiteList({
            eventId: eventId!.id,
            email,
            firstName,
            lastName,
          })
        } catch (error) {
          setRequestStatus('pending')
          onRequestError('Something went wrong! Please, try again.')
        }
      }

      // If user is registered, we just update its data
      const {
        isRegistered: isRegisteredAlready,
      } = await registrationApi.checkRegistration({
        eventId: eventId!.id,
        userEmail: email,
      })

      try {
        await registrationApi.registerUser({
          eventId: eventId!.id,
          firstName,
          lastName,
          userEmail: email,
          isKaiserEmployee,
          company,
          jobTitle,
          location,
          hearAbout,
        })
      } catch (error) {
        errorHandler(error, {
          eventId: eventId!.id,
          userEmail: email,
          others: {
            state: 'registration',
            eventId: eventId!.id,
            firstName,
            lastName,
            userEmail: email,
            isKaiserEmployee,
            company,
            jobTitle,
            location,
            hearAbout,
          },
        })
        setRequestStatus('pending')
        onRequestError('Something went wrong! Please, try again.')
      }

      sendSignInMagicLink(email, 'register', isRegisteredAlready)
        .then(() => {
          setRequestStatus('complete')
          onRequestComplete()
        })
        .catch((error: Error) => {
          setRequestStatus('pending')
          showErrorModal(error)
        })
    }
  }

  const handlerIsKaiserEmployeeValidation = (isKaiserEmployeeValue: string) => {
    if (isKaiserEmployeeValue === 'yes') {
      setIsKaiserEmployee(true)
      setCompany('Kaiser Permanente')
    } else {
      setIsKaiserEmployee(false)
      setCompany('')
    }

    setTimeout(() => {
      if (form) {
        setIsFormValid(form.checkValidity())
      }
    }, 0)
  }

  return (
    <>
      {requestStatus === 'not_started' && (
        <div
          id="registration"
          className="bg-white w-full pb-12 pt-8 px-4 md:px-12 rounded-b-lg shadow-finish-signup-form"
        >
          <Button
            size="large"
            color="blue"
            additionalClass="m-auto"
            onClick={() => setRequestStatus('pending')}
          >
            Register
          </Button>
        </div>
      )}
      {requestStatus === 'pending' && (
        <div
          id="registration"
          className="bg-white w-full pb-12 pt-8 px-4 md:px-12 rounded-b-lg shadow-finish-signup-form"
        >
          <GradientText className="mb-11 sm:ml-2 text-3xl font-semibold self-center text-center sm:text-left sm:text-4xl">
            Event Registration
          </GradientText>
          <div className="flex flex-col justify-between items-center">
            <form
              ref={(formRef) => {
                setForm(formRef as HTMLFormElement)
              }}
              onChange={(event) => {
                setIsFormValid(event.currentTarget.checkValidity())
              }}
              onSubmit={handlerOnRegister}
              className="flex flex-col w-full"
            >
              <Input
                type="text"
                id="firstName"
                label="First Name"
                placeholder="First Name*"
                errorMessage="This field is required"
                required
                onValidValue={(firstNameValue) => setFirstName(firstNameValue)}
              />

              <Input
                type="text"
                id="lastName"
                label="Last Name"
                placeholder="Last Name*"
                errorMessage="This field is required"
                required
                onValidValue={(lastNameValue) => setLastName(lastNameValue)}
              />

              <Input
                type="email"
                id="email"
                placeholder="Email*"
                label="Email"
                errorMessage="Please enter a valid email address"
                pattern={patterns.emailPattern}
                required
                onValidValue={(emailValue) => setEmail(emailValue)}
                onInvalidValue={() => setEmail('')}
                getRef={(ref) => {
                  emailInputRef = ref
                }}
              />

              <RadioInput
                id="isKaiserEmployee"
                label="Are you currently an employee of Kaiser Permanente?"
                radioOptions={[
                  {
                    label: 'Yes',
                    value: 'yes',
                    isDefault: true,
                  },
                  { label: 'No', value: 'no' },
                ]}
                onValidValue={handlerIsKaiserEmployeeValidation}
              />

              {!isKaiserEmployee && (
                <Input
                  type="text"
                  id="company"
                  label="What company do you work for?"
                  placeholder="What company do you work for?*"
                  errorMessage="This field is required"
                  required
                  onValidValue={(companyValue) => setCompany(companyValue)}
                />
              )}

              <Input
                type="text"
                id="jobTitle"
                label="Job Title"
                placeholder="Job Title*"
                errorMessage="This field is required"
                required
                onValidValue={(jobTitleValue) => setJobTitle(jobTitleValue)}
              />

              <Input
                type="text"
                id="location"
                label="Location"
                placeholder="Location"
                errorMessage="This field is required"
                onValidValue={(locationValue) => setLocation(locationValue)}
              />

              <Select
                id="hearAbout"
                label="How did you hear about the event?"
                selectOptions={[
                  { label: 'LinkedIn', value: 'linkedin' },
                  { label: 'Twitter', value: 'twitter' },
                  { label: 'Email', value: 'email' },
                  { label: 'Other', value: 'other' },
                ]}
                onValidValue={(hearAboutValue) => {
                  setHearAbout(hearAboutValue)
                }}
              />

              <div className="self-center sm:self-end">
                <Submit
                  large
                  showArrowIcon
                  value="Register"
                  disabled={!isFormValid}
                  onClick={onStartRequest}
                />
              </div>
            </form>
          </div>
        </div>
      )}

      {requestStatus === 'in_progress' && (
        <div className="bg-pebble w-full flex flex-col justify-between items-center pb-12 pt-8 shadow-finish-signup-form-preloader rounded-b-lg">
          <shared.Loader />
        </div>
      )}
    </>
  )
}

export default RegisterForm
