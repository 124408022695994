import React, { useState, useEffect, useCallback, useContext } from 'react'

import * as shared from 'pericles-shared'

import CurrentEventContext from 'context/event'
import GradientText from 'components/gradient-text'
import ThinLine from 'components/thin-line'
import EventDateTime from 'components/event-date-time'
import thoughtcastLogo from 'assets/svg/thoughtcast-logo.svg'
import useEventProperties from 'hooks/use-event-properties'
import HeaderAreaContext from 'context/header-area'
import scrollToElement from 'utils/scroll-to-element'
import LoginModalBody from 'components/login-modal-body'
import ModalContext from 'context/modal'
import UserService from 'services/user-service'
import RegisterForm from './forms/register-form'
import WatchNowForm from './forms/watch-now-form'
import MainFormTypes from './main-form-types'
import {
  RegistrationEmailSentStatus,
  EmailConfirmedStatus,
  ErrorStatus,
  RequestAccessSentStatus,
  RegisteredRequestAccessStatus,
  SuccessfullyLoggedInStatus,
  LiveNoAccessStatus,
  RegisteredForEventSuccessfullyStatus,
  AlreadyRegisteredStatus,
  PreloaderStatus,
} from './message-status'

const { Countdown } = shared

interface MainFormDisplayProps {
  formType: MainFormTypes
  loadingState?: boolean
}

const MainFormDisplay: React.FC<MainFormDisplayProps> = ({
  formType,
  loadingState = false,
}) => {
  const [statusMessage, setStatusMessage] = useState<React.ReactNode | null>()
  const { setElementReference } = useContext(HeaderAreaContext)
  const { showModal } = useContext(ModalContext)
  const [stateBlocked, setStateBlocked] = useState(false)
  const event = CurrentEventContext.useContainer()
  const { eventState } = useEventProperties(event!.id)
  const { user } = UserService.getInstance()

  let eventStart
  let eventEnd
  if (eventState) {
    eventStart = eventState.eventStart.toDate()
    eventEnd = eventState.eventEnd.toDate()
  }

  // Set where the header background height should go
  useEffect(() => {
    const elementReference = document.getElementById('headerHeightLimit')
    if (elementReference) {
      setElementReference(elementReference)
    }
  }, [setElementReference])

  useEffect(() => {
    if (!stateBlocked) {
      if (formType === MainFormTypes.RegistrationForm) {
        setStatusMessage(<></>)
      }

      if (formType === MainFormTypes.FinishSignupForm) {
        setStatusMessage(<EmailConfirmedStatus />)
      }

      if (formType === MainFormTypes.SignupCompleteForm) {
        setStatusMessage(<AlreadyRegisteredStatus />)
      }

      if (formType === MainFormTypes.Registered) {
        setStatusMessage(<RegisteredForEventSuccessfullyStatus />)
      }

      if (formType === MainFormTypes.RegisteredAndNotInWhitelistForm) {
        setStatusMessage(<RequestAccessSentStatus />)
      }

      if (formType === MainFormTypes.RegisteredAndRequestAccessForm) {
        setStatusMessage(<RegisteredRequestAccessStatus />)
      }

      if (formType === MainFormTypes.SuccessfullyLoggedInForm) {
        setStatusMessage(<SuccessfullyLoggedInStatus />)
      }

      if (formType === MainFormTypes.LiveAndLoggedOutForm) {
        setStatusMessage(<></>)
      }

      if (formType === MainFormTypes.LiveAndLoggedInWithoutAccessForm) {
        setStatusMessage(<></>)
      }

      if (formType === MainFormTypes.LiveAndLoggedInWithAccessForm) {
        setStatusMessage(<></>)
      }
    }
  }, [formType, loadingState, eventState, stateBlocked])

  const handlerOnError = (
    errorMessage: string,
    bottonRounded?: boolean,
    confirmButtonLabel?: string,
    onClickConfirm?: () => void
  ) => {
    // Send user to the top, so that he/she can see the progress status
    scrollToElement('statusContainer')
    setStatusMessage(
      <ErrorStatus
        errorMessage={errorMessage}
        confirmButtonLabel={confirmButtonLabel}
        onClickConfirm={onClickConfirm}
        bottomRounded={bottonRounded}
      />
    )
  }

  useEffect(() => {
    if (formType === MainFormTypes.LiveAndLoggedOutForm && stateBlocked) {
      setStatusMessage(<></>)
    }
  }, [formType, stateBlocked])

  const form = useCallback(() => {
    const handlerOnRegistrationLinkSent = () => {
      setStateBlocked(true)
      scrollToElement('statusContainer')
      setStatusMessage(<RegistrationEmailSentStatus />)
    }

    const handlerOnRequestAccess = () => {
      scrollToElement('statusContainer')
      setStatusMessage(<RequestAccessSentStatus />)
    }

    const handlerOnRegisteredAndNotInWhitelist = () => {
      scrollToElement('statusContainer')
      setStatusMessage(<RegisteredRequestAccessStatus />)
    }

    const handleOnClickSignin = () => {
      showModal({
        showCloseButton: true,
        customBody: <LoginModalBody />,
      })
    }

    if (formType === MainFormTypes.Preloader) {
      return <PreloaderStatus />
    }

    if (formType === MainFormTypes.RegistrationForm) {
      return (
        <RegisterForm
          onRequestComplete={handlerOnRegistrationLinkSent}
          onRequestAccessAlreadySent={handlerOnRequestAccess}
          onRegisteredAndNotInWhitelist={handlerOnRegisteredAndNotInWhitelist}
          onRequestError={handlerOnError}
        />
      )
    }
    if (formType === MainFormTypes.LiveAndLoggedOutForm) {
      return (
        <>
          <div className="py-8 bg-white">
            <h1 className="text-deep-blue font-avenir-next text-4xl text-center">
              ThoughtCAST is live!
            </h1>
            <p className="text-inky font-avenir-next text-base text-center mt-4">
              <button
                onClick={handleOnClickSignin}
                type="button"
                aria-label="Login"
                className="underline"
              >
                Log in
              </button>{' '}
              to join
            </p>
          </div>
          <div
            id="loginForm"
            className="bg-pebble w-full flex flex-col justify-between items-center pb-12 pt-8 shadow-dropdown rounded-b-lg"
          >
            <LoginModalBody hideCopy />
          </div>
        </>
      )
    }

    if (formType === MainFormTypes.LiveAndLoggedInWithoutAccessForm) {
      return <LiveNoAccessStatus />
    }

    if (formType === MainFormTypes.LiveAndLoggedInWithAccessForm) {
      return <WatchNowForm />
    }

    return null
  }, [formType, showModal])()

  return (
    <div
      className="w-auto flex flex-col mx-8 md:m-auto z-10"
      style={{ maxWidth: '768px', marginTop: '58px' }}
    >
      <img
        src={thoughtcastLogo}
        alt="THOUGHTCAST logo"
        className="self-center mb-6 mt-6"
      />

      {eventStart && eventEnd && (
        <EventDateTime
          eventStart={eventStart}
          eventEnd={eventEnd}
          className="flex justify-center items-center font-avenir-next text-white text-center text-2xl"
        />
      )}

      <div
        className={`flex flex-col justify-between items-center mt-12 w-auto bg-white py-12 px-12 pb-0 shadow-dropdown rounded-t-lg ${
          formType === MainFormTypes.None ? 'rounded-b-lg shadow-dropdown' : ''
        }`}
      >
        <GradientText className="mb-4 text-3xl font-semibold self-center text-center sm:text-4xl">
          Learn. Discover. Connect.
        </GradientText>
        <p className="text-mid-blue font-avenir-next text-center font-semibold text-lg mb-4">
          Join us for a deep-dive on the future of home care.
        </p>
        <p className="text-dolphin font-avenir-next text-left sm:text-center">
          Why is in-home care important? Why is it significant to shift care
          into the home? Where does Kaiser Permanente’s work fit into the larger
          ecosystem and beyond? What are the key technologies enhancing home
          health today and in the future? How might we think about healing and
          the psychology of care as we continue to innovate in this space? How
          can we ensure equitable access and a seamless system, to capitalize on
          the possibilities of home care for all?
        </p>
        <ThinLine
          className={`mt-8 ${
            (eventState!.currentState === 'live' &&
              user.fbUser &&
              !user.isRestricted) ||
            !user.fbUser
              ? 'mb-1'
              : null
          }`}
        />
        {eventState!.currentState !== 'live' && (
          <Countdown
            date={eventState!.eventStart.toDate()}
            containerClass="flex justify-between text-black font-avenir-next text-dolphin text-3xl font-normal w-full pb-8 sm:text-7xl mt-8"
            labelClass="text-xs text-center font-semibold sm:text-xl"
            timerClass="text-center"
          />
        )}
      </div>
      <span id="headerHeightLimit" />
      {loadingState && !user ? (
        <div className="bg-pebble w-full flex flex-col justify-between items-center pb-12 pt-8 shadow-dropdown rounded-b-lg">
          <shared.Loader />
        </div>
      ) : (
        <>
          <div id="statusContainer">{statusMessage}</div>
          {form}
        </>
      )}
    </div>
  )
}

export default MainFormDisplay
