import React from 'react'
import UserService from 'services/user-service'
import RoundedBottomContainer from './rounded-bottom-container'

const LiveNoAccessStatus: React.FC = () => {
  const userService = UserService.getInstance()

  return (
    <>
      <RoundedBottomContainer>
        <div className="flex flex-col items-center">
          <h4 className="px-0 sm:px-12 text-red">
            You do not have access to join this event.
          </h4>
          <p className="pt-4 sm:px-12">
            You are logged in as {userService.user.fbUser?.email}, and do not
            have permission to view this event. Please check back later to learn
            more about upcoming events!
          </p>
        </div>
      </RoundedBottomContainer>
    </>
  )
}

export default LiveNoAccessStatus
