import React from 'react'

const NotRoundedBottomContainer: React.FC = ({ children }) => (
  <div className="px-12 bg-pebble w-full flex flex-col justify-between items-center pb-12 pt-8 shadow-dropdown">
    <div className="text-dolphin font-avenir-next text-base pt-4 text-center w-full">
      {children}
    </div>
  </div>
)

export default NotRoundedBottomContainer
