import React from 'react'

const AddProfileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color } = props
  return (
    <svg
      {...props}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.0892 70.9086V64.3634C53.0892 57.1337 47.2284 51.2729 39.9987 51.2729H17.0904C9.86079 51.2729 4 57.1337 4 64.3634V70.9086"
        stroke={color ?? '#846EAD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5455 38.1809C35.7752 38.1809 41.636 32.3201 41.636 25.0904C41.636 17.8608 35.7752 12 28.5455 12C21.3159 12 15.4551 17.8608 15.4551 25.0904C15.4551 32.3201 21.3159 38.1809 28.5455 38.1809Z"
        stroke={color ?? '#846EAD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1819 28.3672V48.0029"
        stroke={color ?? '#846EAD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.0007 38.187H56.365"
        stroke={color ?? '#846EAD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AddProfileIcon
