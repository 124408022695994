import React from 'react'

const Tag: React.FC = ({ children }) => {
  return (
    <span
      className="font-avenir-next font-semibold px-3 py-1 bg-tag-red rounded-r-lg rounded-l-lg"
      style={{ width: 'fit-content' }}
    >
      {children}
    </span>
  )
}

export default Tag
