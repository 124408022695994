import React from 'react'

interface InfoTile {
  icon: JSX.Element
  title: JSX.Element | string
  description: JSX.Element | string
}

const InfoTile: React.FC<InfoTile> = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col justify-start w-info-tile-w h-info-tile-h rounded-md px-4 mx-3 mt-8 xl:mt-0">
      <div className="self-center mb-6">{icon}</div>
      <h2 className="font-avenir-next text-white text-center font-semibold text-xl mb-4">
        {title}
      </h2>
      <div className="font-avenir-next text-white text-sm text-center">
        {description}
      </div>
    </div>
  )
}

export default InfoTile
