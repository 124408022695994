import React, { useState } from 'react'
import Button from './button'

const COOKIE_ALERT_INFO_KEY = 'showCookieBanner'

const CookieBanner: React.FC = () => {
  const cookieData = window.localStorage.getItem(COOKIE_ALERT_INFO_KEY)
  const [showCookieBanner, setShowCookieBanner] = useState(
    !(cookieData && cookieData === 'false')
  )

  const handlerOnClickOk = () => {
    window.localStorage.setItem(COOKIE_ALERT_INFO_KEY, 'false')
    setShowCookieBanner(false)
  }

  if (showCookieBanner) {
    return (
      <div className="flex flex-col p-8 bg-deep-blue justify-between fixed z-50 bottom-0 left-0 right-0 sm:flex-row sm:items-center">
        <p className="font-avenir-next text-white mr-8">
          We use cookies to improve your browsing experience. Find out more
          about our cookie policy{' '}
          <a href="/privacy_policy" className="underline hover:underline">
            here
          </a>
          .
        </p>
        <div>
          <Button
            onClick={handlerOnClickOk}
            color="white"
            additionalClass="mt-4 sm:mt-0"
          >
            Ok
          </Button>
        </div>
      </div>
    )
  }
  return null
}

export default CookieBanner
