import * as types from 'pericles-types'
import { setPresence } from 'api/presence'
import UserService from 'services/user-service'
import { postMessage, hasSWSupport, MessageType } from '.'

export type NotifyPresence = types.SetPresenceInput
let lastStatus = false
let routesFilter: string[] = []

const processNotification = async (presenceData: NotifyPresence) => {
  if (hasSWSupport) {
    const message: NotifyPresence & MessageType = {
      ...presenceData,
      type: 'notify-presence',
    }
    postMessage(message)
    return
  }

  try {
    await setPresence(presenceData)
  } catch (error) {
    console.error('Error sending presence data:', error)
  }
}

/**
 * Set if user is online or offline
 *
 * Limitation: If browser doesn't supports Service Worker, the offline status set
 * may fail. However, almost all browsers has SW support.
 *
 * @param presenceData
 */
export const notifyPresence = (presenceData: NotifyPresence) => {
  if (lastStatus !== presenceData.online && presenceData.eventId) {
    if (presenceData.userName === 'null null') {
      // Try again in a few seconds
      setTimeout(() => {
        const userService = UserService.getInstance()
        const { firstName, lastName } = userService.user
        const updatedPresenceData: NotifyPresence = {
          ...presenceData,
          userName: `${firstName} ${lastName}`,
        }
        notifyPresence(updatedPresenceData)
      }, 3000)
    } else {
      lastStatus = presenceData.online
      processNotification(presenceData)
    }
  }
}

/**
 * Set the routes where the user presence will be checked and set as online
 * @param routes list of routes e.g. ['/', '/watch', '/profile']
 */
export const setRoutesFilter = (routes: string[]) => {
  routesFilter = routes
}

export const getRoutesFilter = () => routesFilter
