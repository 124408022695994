import React, { useContext, memo } from 'react'
import { UserIcon } from 'components/icons'
import ChatContext from 'context/chat'
import usePresenceDetector from 'hooks/use-presence-detector'
import TabButton from './tab-button'
import DefaultTopTabMenuValues from './default-top-tab-menu-values'

const TopTabMenu: React.FC = memo(() => {
  const {
    additionalButtons,
    lastClickedButtonValue,
    isEnabled: isChatEnabled,
  } = useContext(ChatContext)
  const { onlineUsers } = usePresenceDetector()

  return (
    <div className="flex justify-around py-2 bg-white shadow-tab-container z-10 xl:rounded-t-lg xl:w-chat-tab-w">
      {additionalButtons?.map(({ label, value, activated }) => {
        return (
          <TabButton key={label} value={value} activated={activated}>
            {label}
          </TabButton>
        )
      })}
      {isChatEnabled && (
        <TabButton value={DefaultTopTabMenuValues.LiveChat} activated>
          Live Chat
        </TabButton>
      )}
      <TabButton value={DefaultTopTabMenuValues.QAndA}>Q&A</TabButton>
      <TabButton value={DefaultTopTabMenuValues.ActiveUsers}>
        {lastClickedButtonValue === DefaultTopTabMenuValues.ActiveUsers ? (
          <UserIcon width="16" height="16" className="mr-2" color="#FFFFFF" />
        ) : (
          <UserIcon width="16" height="16" className="mr-2" />
        )}
        {onlineUsers ? onlineUsers.length : 0}
      </TabButton>
    </div>
  )
})

export default TopTabMenu
