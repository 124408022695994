import React, { createContext, useState } from 'react'
import Button from 'components/button'

import thoughtcastLogo from 'assets/svg/thoughtcast-logo-blue.svg'
import { CloseIcon } from 'components/icons'

type ModalProps = {
  title?: string
  description?: string
  confirmText?: string
  cancelText?: string
  customBody?: JSX.Element
  showCloseButton?: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

const initialModalProps: ModalProps = {
  title: '',
  description: '',
  confirmText: '',
}

type ShowModalProps = (modalProps: ModalProps) => void

type ModalContextProps = {
  showModal: ShowModalProps
}

const ModalContext = createContext<ModalContextProps>({
  showModal: (modalProps) => modalProps,
})

export default ModalContext

type ModalProviderProps = {
  children: React.ReactNode
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalProps>(initialModalProps)
  const [showModal, setShowModal] = useState(false)

  const modal: ModalContextProps = {
    showModal: (props) => {
      setShowModal(true)
      setModalProps(props)
      window.scrollTo(0, 0)
    },
  }

  const {
    title,
    description,
    confirmText,
    cancelText,
    customBody,
    showCloseButton,
    onConfirm,
    onCancel,
  } = modalProps

  const handlerOnClickConfirm = () => {
    if (onConfirm != null) {
      onConfirm()
    }
    setShowModal(false)
  }

  const handlerOnClickCancel = () => {
    if (onCancel != null) {
      onCancel()
    }
    setShowModal(false)
  }

  const handlerOnClickClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {showModal ? (
        <div className="fixed bg-black bg-opacity-50 w-screen h-screen z-50">
          <div
            className="flex flex-col items-center h-auto bg-white rounded-lg mx-auto mt-modal-center border-black border-solid border border-opacity-25 w-11/12"
            style={{
              maxWidth: 662,
            }}
          >
            {showCloseButton && (
              <button
                aria-label="Close modal"
                type="button"
                className="self-end p-4 focus:outline-none"
                onClick={handlerOnClickClose}
              >
                <CloseIcon width="16" height="16" />
              </button>
            )}
            <div
              className={`flex flex-col w-full items-center ${
                showCloseButton ? 'pb-12' : 'py-12'
              }  px-1 sm:px-12 md:px-12 lg:px-24`}
            >
              <img
                src={thoughtcastLogo}
                width="200"
                height="45"
                alt="ThoughtCast"
              />
              {title && (
                <h4 className="font-avenir-next text-inky text-sm text-center font-normal my-6">
                  {title}
                </h4>
              )}
              {description && (
                <p
                  className={`font-avenir-next text-inky text-lg mb-4 text-center ${!title &&
                    'mt-6'}`}
                >
                  {description}
                </p>
              )}
              {customBody || (
                <div className="flex justify-around">
                  {confirmText && (
                    <Button color="blue" onClick={handlerOnClickConfirm}>
                      {confirmText}
                    </Button>
                  )}
                  {cancelText && (
                    <Button onClick={handlerOnClickCancel}>{cancelText}</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
    </>
  )
}
