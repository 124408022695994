import React, { memo, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import {
  Chat,
  Channel,
  Window,
  MessageList,
  MessageInput,
  SendButtonProps,
  withChannelContext,
  ChannelContextValue,
  MessageProps,
  LoadingIndicatorProps,
} from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'

import { getClient } from 'api/chat'

import useChat from 'hooks/use-chat'
import { SendIcon } from 'components/icons'
import LoadingIcon from 'components/chat/loading-icon'
import ChatContext from 'context/chat'
import DropDownMenu, { DropDownMenuItem } from 'components/form/drop-down-menu'
import CurrentEventContext from 'context/event'

const WatcherCountMirror = withChannelContext(
  ({ watcher_count: watcherCount, loading }: ChannelContextValue) => {
    const { updateOnlineUsersCounter, onlineCounter } = useContext(ChatContext)
    const usersOnlineCounter = watcherCount ?? 0
    if (usersOnlineCounter !== onlineCounter && !loading) {
      setTimeout(() => {
        updateOnlineUsersCounter(usersOnlineCounter)
      }, 0)
    }
    return null
  }
)

const CustomMessage: React.FC<MessageProps> = ({ message }) => {
  const { userId } = useContext(ChatContext)
  const [flagged, setFlagged] = useState(false)

  const itsMe = userId === message?.user?.id
  const itsMeChatStyle = itsMe
    ? 'border-mid-blue ml-16 self-end text-mid-blue'
    : 'border-deep-graphite mr-16 text-inky'
  const itsMeTimeStyle = itsMe ? 'text-right' : 'text-left'
  const itsMeNameStyle = itsMe ? 'text-mid-blue' : 'text-inky'
  const itsMeInfoGroup = itsMe ? 'justify-end' : ''
  // eslint-disable-next-line camelcase
  const sentTime = moment(message?.created_at).format('hh:mma')
  const userName = itsMe ? 'You' : message?.user?.name

  const handleOnFlag = async () => {
    const client = getClient()
    setFlagged(true)
    client.flagMessage(message!.id).catch(() => {
      console.warn('Message is already flagged.')
    })
  }

  const dropDownmenuLabel = (
    <button
      aria-label="Message options"
      type="button"
      className="mr-4 rounded-full w-4 h-4 overflow-hidden"
    >
      <p
        className="font-avenir-next font-semibold text-sm bg-transparent text-shuttle-gray rounded-full w-4 h-4"
        style={{
          lineHeight: '10px',
          paddingLeft: '1.5px',
        }}
      >
        ...
      </p>
    </button>
  )

  if (message && message.type === 'regular' && !flagged) {
    return (
      <div className="mx-4 mt-8 flex flex-col" msg-id={`${message.id}`}>
        {message && (
          <>
            <p
              className={`font-avenir-next p-4 border rounded-modal bg-white ${itsMeChatStyle}`}
              style={{
                width: 'fit-content',
              }}
            >
              {message.text}
            </p>
            <div className={`flex mt-2 ${itsMeInfoGroup}`}>
              {!itsMe && (
                <DropDownMenu
                  useMousePosition
                  label={dropDownmenuLabel}
                  labelContainerStyle={{ marginTop: '-6px' }}
                  className="bg-white text-lg shadow-dropdown cursor-pointer absolute z-20"
                >
                  <DropDownMenuItem onClick={handleOnFlag}>
                    <span className="text-sm font-avenir-next font-semibold rounded-sm text-dolphin bg-white hover:text-inky focus:bg-mid-graphite px-4 py-dropdown-y-margin select-none">
                      Flag
                    </span>
                  </DropDownMenuItem>
                </DropDownMenu>
              )}
              {!itsMe && (
                <span
                  className={`font-avenir-next text-sm font-semibold mr-4 ${itsMeNameStyle}`}
                >
                  {userName}
                </span>
              )}
              <span
                className={`font-avenir-next text-dolphin text-sm ${itsMeTimeStyle}`}
              >
                {sentTime}
              </span>
              {itsMe && (
                <span
                  className={`font-avenir-next text-sm font-semibold ml-4 ${itsMeNameStyle}`}
                >
                  {userName}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
  return null
}

const CustomSendButton: React.FC<SendButtonProps> = ({ sendMessage }) => {
  return (
    <button
      aria-label="Send message"
      onClick={sendMessage}
      type="button"
      className="px-2 focus:outline-none bg-none"
      style={{
        // Small adjustemnt due to the Default Chat UI style
        marginTop: '-8px',
      }}
    >
      <SendIcon width="32" height="32" />
    </button>
  )
}

const CustomLoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size,
  color,
}) => {
  // Removes the input created by the chat input component
  useEffect(() => {
    return () => {
      setTimeout(() => {
        const chatInputList = document.getElementsByClassName(
          'str-chat__input-large'
        )
        if (chatInputList && chatInputList[0]) {
          const inputs = chatInputList[0].getElementsByTagName('input')
          for (let i = 0; i < inputs.length; i += 1) {
            const currentItem = inputs.item(i)
            if (currentItem && currentItem.type === 'file') {
              currentItem.remove()
            }
          }
        }
      }, 0)
    }
  }, [])

  return <LoadingIcon size={size} color={color} />
}

const LiveChat = memo(() => {
  const event = CurrentEventContext.useContainer()
  const { chatClient, chatChannel } = useChat(event!.id)

  return (
    <>
      {chatClient && chatChannel && (
        <Chat client={chatClient} theme="kp-chat-theme">
          <Channel
            client={chatClient}
            channel={chatChannel}
            LoadingIndicator={CustomLoadingIndicator}
          >
            <WatcherCountMirror closeThread={() => null} client={chatClient} />
            <Window>
              <MessageList
                Message={CustomMessage}
                closeThread={() => null}
                client={chatClient}
              />
              <div className="flex shadow-chat-input-container">
                <MessageInput
                  noFiles
                  additionalTextareaProps={{
                    placeholder: 'Message the group',
                    'aria-label': 'Message the group',
                  }}
                  SendButton={CustomSendButton}
                />
              </div>
            </Window>
          </Channel>
        </Chat>
      )}
    </>
  )
})

export default LiveChat
