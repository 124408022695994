import { useContext } from 'react'

import ChatContext from 'context/chat'
import { getClient, getChannel, setUser } from 'api/chat'

const useChat = (eventName: string) => {
  const { userId, userName, userPhotoURL, streamChatUserToken } = useContext(
    ChatContext
  )

  if (userId && userName && userPhotoURL && streamChatUserToken) {
    setUser(userId, userName, userPhotoURL, streamChatUserToken)
    return { chatClient: getClient(), chatChannel: getChannel(eventName) }
  }

  return { chatClient: null, chatChannel: null }
}

export default useChat
