import React, { createRef, useEffect, useState } from 'react'

interface DropDownMenuItemProps {
  onClick?: () => void
  className?: string
}

export const DropDownMenuItem: React.FC<DropDownMenuItemProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <li
      onMouseDownCapture={onClick}
      className={
        className ||
        'font-avenir-next text-lg text-inky px-4 py-dropdown-y-margin select-none'
      }
    >
      {children}
    </li>
  )
}

interface DropDownMenuProps {
  label: React.ReactNode
  labelContainerStyle?: {}
  className?: string
  useMousePosition?: boolean
}

interface ItemPosition {
  right?: number
  top: number
  left?: number
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({
  children,
  label,
  labelContainerStyle,
  className,
  useMousePosition,
}) => {
  const [itemsPosition, setItemsPosition] = useState<ItemPosition>({
    right: 0,
    top: 0,
  })
  const [isOpen, setIsOpen] = useState(false)
  const labelRef = createRef<HTMLDivElement>()
  const dropdownReference = createRef<HTMLDivElement>()

  useEffect(() => {
    const handleOnMouseMove = (event: MouseEvent) => {
      const curEvent = event || window.event
      setItemsPosition({
        left: curEvent.clientX,
        top: curEvent.clientY,
      })
    }

    if (!useMousePosition) {
      if (labelRef.current != null) {
        const offsetHeight = labelRef.current?.offsetHeight
        const offsetTop = labelRef.current?.offsetTop
        const labelHeight = labelRef.current?.clientHeight
        setItemsPosition({
          right: offsetHeight ? offsetHeight - 9 : 0,
          top: offsetTop && labelHeight ? offsetTop + labelHeight : 0,
        })
      }
    } else {
      document.addEventListener('mousedown', handleOnMouseMove)
    }

    return () => {
      document.removeEventListener('mousedown', handleOnMouseMove)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    const handlerOnMouseDown = (event: MouseEvent) => {
      if (!dropdownReference.current?.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handlerOnMouseDown)
    return () => {
      document.removeEventListener('mousedown', handlerOnMouseDown)
    }
  }, [dropdownReference])

  return (
    <div
      ref={dropdownReference}
      onClickCapture={() => setIsOpen(!isOpen)}
      className="flex flex-col"
    >
      <div ref={labelRef} style={labelContainerStyle}>
        {label}
      </div>
      <ul
        className={
          className ||
          'font-avenir-next bg-white text-lg shadow-dropdown cursor-pointer absolute'
        }
        style={{
          ...(itemsPosition.right ? { right: itemsPosition.right } : {}),
          ...(itemsPosition.left
            ? { left: itemsPosition.left, position: 'fixed', zIndex: 99 }
            : {}),
          top: itemsPosition.top,
          display: isOpen ? 'block' : 'none',
        }}
      >
        {children}
      </ul>
    </div>
  )
}

export default DropDownMenu
