/**
 * This code needs "app-service-worker.js" file in the "public" folder
 */

import firebaseConfig from './firebase-config'

export interface MessageType {
  type: 'notify-presence' | 'setup-firebase'
}

let currentRegistration: ServiceWorkerRegistration | null

const onSWReady = (registration: ServiceWorkerRegistration) => {
  if (!currentRegistration) {
    currentRegistration = registration
    const message: MessageType & { firebaseConfig: typeof firebaseConfig } = {
      type: 'setup-firebase',
      firebaseConfig,
    }
    postMessage(message)
  }
}

/**
 * Has Service Worker support?
 */
export const hasSWSupport = Boolean('serviceWorker' in navigator)

/**
 * Install Service Worker
 */
export const install = async () => {
  if (hasSWSupport) {
    navigator.serviceWorker.ready.then(onSWReady)

    navigator.serviceWorker
      .register('/app-service-worker.js')
      .then((registration) => {
        onSWReady(registration)
      })
  }
}

/**
 * Post message
 * @param data
 */
export function postMessage(data: MessageType) {
  if (!data.type) {
    throw new Error('You must specify the message type')
  }

  if (!currentRegistration || !currentRegistration.active) {
    console.warn('Service work is not ready yet. Trying again...')
    setTimeout(() => {
      postMessage(data)
    }, 3000)
    return
  }

  currentRegistration.active!.postMessage(data)
}
