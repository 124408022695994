import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { SingletonProvider } from 'reactive-singleton'

import * as shared from 'pericles-shared'
import App from 'app'
import CookieBanner from 'components/cookie-banner'
import * as worker from 'worker'
import * as notifyPresence from 'worker/notify-presence'

import 'index.scss'

ReactDOM.render(
  <shared.Provider>
    <BrowserRouter>
      <SingletonProvider>
        <CookieBanner />
        <App />
      </SingletonProvider>
    </BrowserRouter>
  </shared.Provider>,
  document.getElementById('pericles-module')
)

// Service Worker
worker.install()
notifyPresence.setRoutesFilter(['/watch'])
