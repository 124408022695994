import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

const useEventDocumentInfo = (event: string, listen = true) => {
  const { data: eventDocumentInfo } = shared.useDocument<
    types.EventDocumentInfo
  >(`events/${event}`, {
    listen,
    suspense: true,
  })

  return eventDocumentInfo as types.EventDocumentInfo
}

export default useEventDocumentInfo
