import React from 'react'
import { StarIcon, LightIcon, AddProfileIcon } from 'components/icons'
import ThinLine from 'components/thin-line'
import InfoTile from './info-tile'

const InfoSection: React.FC = () => {
  return (
    <div>
      <div className="mx-12 mt-20 mb-4 xl:mx-32">
        <h4 className="font-avenir-next text-white text-4xl font-semibold text-center mb-12">
          About the Event
        </h4>
        <p className="font-avenir-next text-white text-xl">
          ThoughtCAST is an initiative designed to stimulate cross-industry
          dialogue on topics that will define the future of healthcare and
          wellbeing in the 21st century. Presented by Kaiser Permanente Digital,
          the speaker series features cross-industry thought leaders with Kaiser
          Permanente executives to examine pressing topics at the intersection
          of healthcare, technology, and human connection.
        </p>
        <ThinLine color="white" className="mt-12" />
      </div>
      <div className="flex flex-wrap justify-evenly items-center pb-16 pt-8 xl:pb-16">
        <InfoTile
          icon={<StarIcon color="#FFFFFF" />}
          title={
            <>
              Hear from <br />
              Our Speakers
            </>
          }
          description="Listen to engaging dialogue between Kaiser Permanente leaders and industry top minds"
        />
        <InfoTile
          icon={<LightIcon color="#FFFFFF" />}
          title={
            <>
              Engage <br />
              During the Event
            </>
          }
          description="Discover and participate in live chat, Q&A, and live polls"
        />
        <InfoTile
          icon={<AddProfileIcon color="#FFFFFF" />}
          title={
            <>
              Network <br />
              Afterwards
            </>
          }
          description="Continue to connect with fellow colleagues and our guest speakers in the event chatroom"
        />
      </div>
    </div>
  )
}

export default InfoSection
