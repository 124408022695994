import React, { useEffect, useState } from 'react'
import moment from 'moment'

import CurrentEventContext from 'context/event'
import useEventProperties from 'hooks/use-event-properties'
import Tag from './tag'

interface SessionDetails {
  className?: string
}

const SessionDetails: React.FC<SessionDetails> = ({ className }) => {
  const event = CurrentEventContext.useContainer()
  const { eventState, eventMeta } = useEventProperties(event!.id)
  const [startTime, setStartTime] = useState('...')
  const [endTime, setEndTime] = useState('...')

  useEffect(() => {
    if (eventState) {
      const { eventStart, eventEnd } = eventState
      setStartTime(
        moment(eventStart.toDate())
          .format('hh:mmA')
          .toLowerCase()
      )

      setEndTime(
        moment(eventEnd.toDate())
          .format('hh:mmA')
          .toLowerCase()
      )
    }
  }, [eventMeta, eventState])

  return (
    <div className={className}>
      <Tag>Live</Tag>
      <h2 className="font-avenir-next font-semibold text-4xl mt-6 text-white">
        {eventMeta?.title}
      </h2>
      <h3 className="font-avenir-next font-semibold text-xl text-white mb-3">
        {startTime} - {endTime}
      </h3>
      <p className="font-avenir-next text-base text-left">
        {eventMeta?.description}
      </p>
    </div>
  )
}

export default SessionDetails
