import React from 'react'
import { UserResponse } from 'stream-chat'
import * as shared from 'pericles-shared'

import usePresenceDetector from 'hooks/use-presence-detector'
import uniqueId from 'utils/unique-id'
import UserService from 'services/user-service'

type User = UserResponse & {
  name: string
}

const ActiveUsers: React.FC = () => {
  const { onlineUsers } = usePresenceDetector()
  const userService = UserService.getInstance()
  const { user } = userService

  return (
    <>
      {!onlineUsers ? (
        <div className="flex justify-center items-center h-full">
          <shared.Loader />
        </div>
      ) : (
        <ul
          className="m-4 overflow-scroll"
          style={{
            height: '94%',
          }}
        >
          {onlineUsers?.map(({ userName, userId }) => {
            if (userName) {
              const [firstName, lastName] = userName.split(' ')
              const itsMe = userId === user.fbUser?.uid

              return (
                <li
                  key={uniqueId()}
                  className="font-avenir-next text-inky py-4 border-black border-opacity-25 border-b font-semibold text-sm"
                >
                  {itsMe
                    ? `${firstName} ${lastName} - You`
                    : `${firstName} ${lastName}`}
                </li>
              )
            }
            return null
          })}
        </ul>
      )}
    </>
  )
}

export default ActiveUsers
