import React, { useState, useContext } from 'react'
import * as shared from 'pericles-shared'
import useEventDocumentInfo from 'hooks/use-event-document-info'
import CurrentEventContext from 'context/event'
import {
  registerUser,
  getUserRegistration,
  checkRegistration,
} from 'api/registration'
import { getBasicWhitelistUserInfo } from 'api/auth'

import ModalContext from 'context/modal'
import { Input, patterns, Submit, InputFormatTypes } from './form'

const SimpleUpcomingEvent: React.FC = () => {
  const currentEvent = CurrentEventContext.useContainer()
  const { nextEventId } = useEventDocumentInfo(currentEvent!.id, false)
  const [email, setEmail] = useState('')
  const user = shared.AuthContext.useContainer()
  const { showModal } = useContext(ModalContext)
  const [requestStatus, setRequestStatus] = useState<
    'pending' | 'in_progress' | 'complete'
  >('pending')

  let emailInputRef: React.RefObject<HTMLInputElement>

  const showErrorModal = () => {
    showModal({
      title: 'Something went wrong!',
      description: 'Unable to register you. Try again, please.',
      confirmText: 'Ok',
    })
  }

  const registerUserRequest = async () => {
    const userAccessToken = await user!.getIdToken()
    const currentUserRegistration = await getUserRegistration({
      userEmail: user!.email as string,
      accessToken: userAccessToken,
    })
    await getBasicWhitelistUserInfo({
      email: user!.email as string,
      eventId: currentEvent!.id,
    })

    if (currentUserRegistration) {
      const {
        firstName,
        lastName,
        userEmail,
        isKaiserEmployee,
        company,
        jobTitle,
        location,
        hearAbout,
      } = currentUserRegistration
      await registerUser({
        eventId: nextEventId,
        firstName,
        lastName,
        userEmail,
        isKaiserEmployee,
        company,
        jobTitle,
        location,
        hearAbout,
      })

      setRequestStatus('complete')
    } else {
      setRequestStatus('pending')
      showErrorModal()
    }
  }

  const handlerOnRegister = async (
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    if (event) {
      event.preventDefault()
    }
    if (emailInputRef.current && !emailInputRef.current?.checkValidity()) {
      emailInputRef.current.focus()
      emailInputRef.current.blur()
    }

    if (email && user) {
      setRequestStatus('in_progress')

      try {
        // Check if user is already registered in the upcoming event
        const { isRegistered: isRegisteredUpcoming } = await checkRegistration({
          eventId: nextEventId,
          userEmail: user.email as string,
        })

        if (!isRegisteredUpcoming) {
          registerUserRequest()
        } else {
          setRequestStatus('complete')
        }
      } catch (error) {
        setRequestStatus('pending')
        showErrorModal()
      }
    } else {
      // Logged out - Register only using email
      setRequestStatus('in_progress')
      const { isRegistered: isRegisteredUpcoming } = await checkRegistration({
        eventId: nextEventId,
        userEmail: email,
      })

      if (!isRegisteredUpcoming) {
        await registerUser({
          eventId: nextEventId,
          firstName: '',
          lastName: '',
          userEmail: email,
          isKaiserEmployee: true,
          company: '',
          jobTitle: '',
          location: '',
          hearAbout: '',
        })

        setRequestStatus('complete')
      } else {
        setRequestStatus('complete')
      }
    }
  }

  if (nextEventId) {
    return (
      <div className="flex flex-col px-speakers-padding-x py-speakers-padding-y w-full background-image-secondary-background pb-24">
        <section>
          <div className="flex flex-col justify-center items-center">
            {requestStatus === 'pending' && (
              <>
                <h3 className="font-avenir-next font-semibold text-2xl text-light-rose mb-6 text-center">
                  Want more ThoughtCAST? Stay connected for upcoming sessions.
                </h3>
                <form
                  onSubmit={handlerOnRegister}
                  className="flex flex-col w-full sm:w-auto sm:flex-row"
                  noValidate
                >
                  <Input
                    type="email"
                    id="email"
                    placeholder="Email*"
                    label="Email"
                    errorMessage="Please enter a valid email address"
                    errorColor="#FFA4B4"
                    pattern={patterns.emailPattern}
                    required
                    group={<Submit value="Sign Up" />}
                    inputFormat={InputFormatTypes.Rounded}
                    onValidValue={(emailValue) => setEmail(emailValue)}
                    onInvalidValue={() => setEmail('')}
                    getRef={(ref) => {
                      emailInputRef = ref
                    }}
                  />
                </form>
              </>
            )}
            {requestStatus === 'complete' && (
              <h3 className="font-avenir-next font-semibold text-2xl text-light-rose mb-6 text-center">
                Thank you!
              </h3>
            )}
            {requestStatus === 'in_progress' && (
              <div className="w-full flex flex-col justify-between items-center mt-8">
                <shared.Loader color="#FFA4B4" />
              </div>
            )}
          </div>
        </section>
      </div>
    )
  }
  return null
}

export default SimpleUpcomingEvent
