import React from 'react'
import ThinLine from 'components/thin-line'
import SpeakerSectionTypes from './speaker-section-types'

interface SpeakerTileProps {
  imageSource: string
  name: string
  jobTitle: string
  topic?: string
  sectionType: SpeakerSectionTypes
}

const SpeakerTile: React.FC<SpeakerTileProps> = ({
  imageSource,
  name,
  jobTitle,
  topic,
  sectionType,
}) => {
  if (sectionType === SpeakerSectionTypes.Landing) {
    return (
      <div className="flex flex-col flex-grow flex-shrink-0 max-w-speaker-tile-area w-speaker-tile-area mx-4 mb-speaker-tile-bottom-margin">
        <img
          src={imageSource}
          alt={`Speaker: ${name}`}
          className="w-speaker-thumbnail h-speaker-thumbnail rounded-full self-center mb-xs-margin"
        />
        <p className="text-center font-semibold text-xl text-white font-avenir-next">
          {name}
        </p>
        <p className="text-center font-normal text-lg mb-xs-margin text-light-violet font-avenir-next">
          {jobTitle}
        </p>
        {topic && topic.length ? (
          <>
            <ThinLine className="mb-base-margin" />
            <h6 className="text-center text-dolphin text-xs font-avenir-next font-semibold">
              Topic
            </h6>
            <p className="text-center text-white text-xs font-avenir-next font-semibold">
              {topic}
            </p>
          </>
        ) : null}
      </div>
    )
  }
  return (
    <div className="flex flex-grow mb-8 w-full md:max-w-half xl:max-w-speaker-tile-area-live md:pr-speaker-tile-live-padding">
      <img
        src={imageSource}
        alt={`Speaker: ${name}`}
        className="w-20 h-20 rounded-full"
      />
      <div className="flex flex-col ml-6">
        <p className="text-white font-semibold text-xl font-avenir-next">
          {name}
        </p>
        <p className="text-whitefont-normal text-lg font-avenir-next">
          {jobTitle}
        </p>
      </div>
    </div>
  )
}

export default SpeakerTile
