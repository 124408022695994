import { useEffect, useCallback, useState } from 'react'
import moment from 'moment'

import * as shared from 'pericles-shared'
import * as types from 'pericles-types'
import {
  notifyPresence,
  NotifyPresence,
  getRoutesFilter,
} from 'worker/notify-presence'
import CurrentEventContext from 'context/event'
import UserService from 'services/user-service'
import useDebounce from './use-debounce'

type PresenceData = types.SetPresenceInput & {
  readonly lastAccess: shared.firebase.firestore.Timestamp
  readonly userId: string
}

const { firestore } = shared.firebase

/**
 * When this hook is used, the user will be set as "online".
 * If user close its browser/tab its status will be set as "offline"
 * In case you want to force "offline" status (when user is out of a specific route for example)
 * there is a forceOffline method returned by this hook.
 *
 * This is possible to get the online users data as well using the onlineUsers method
 */
const usePresenceDetector = () => {
  const [onlineUsers, setOnlineUsers] = useState<PresenceData[]>()
  const [
    presetNotification,
    setPresetNotification,
  ] = useState<NotifyPresence | null>()
  const notificationData = useDebounce<NotifyPresence | null | undefined>(
    presetNotification,
    500
  )
  const eventId = CurrentEventContext.useContainer()
  const userService = UserService.getInstance()
  const {
    user: { firstName, lastName, fbUser },
  } = userService

  const { data: presenceData } = shared.useCollection<PresenceData>(
    `events/${eventId!.id}/presence`,
    {
      where: [['online', '==', true]],
      listen: true,
    }
  )

  useEffect(() => {
    if (presenceData) {
      const timeValidation = moment()
        .subtract(12, 'hours')
        .toDate()

      const onlineData = presenceData?.filter(({ lastAccess }) => {
        const { nanoseconds, seconds } = lastAccess
        const lastRecordedAccess = new firestore.Timestamp(
          seconds,
          nanoseconds
        ).toDate()
        return lastRecordedAccess > timeValidation
      })

      setOnlineUsers(onlineData)
    }
  }, [presenceData])

  /**
   * Force offline status
   *
   * @param {boolean} immediately Should immediately send offline notification or wait for debouncing process
   */
  const forceOffline = useCallback(
    (immediately = true) => {
      if (immediately) {
        notifyPresence({
          eventId: eventId!.id,
          online: false,
          userName: `${firstName} ${lastName}`,
        })
      } else {
        setPresetNotification({
          eventId: eventId!.id,
          online: false,
          userName: `${firstName} ${lastName}`,
        })
      }
    },
    [eventId, firstName, lastName]
  )

  useEffect(() => {
    const routesToCheckStatus = getRoutesFilter()
    const isValidRoute = routesToCheckStatus.find(
      (route) => route === window.location.pathname
    )

    const hasRoutes = routesToCheckStatus.length > 0
    const online = !hasRoutes || isValidRoute !== undefined

    if (fbUser) {
      setPresetNotification({
        eventId: eventId!.id,
        online,
        userName: `${firstName} ${lastName}`,
      })
    }

    window.onunload = () => {
      forceOffline()
    }
  }, [eventId, fbUser, firstName, forceOffline, lastName])

  useEffect(() => {
    if (notificationData) {
      notifyPresence(notificationData)
    }
  }, [notificationData])

  return {
    forceOffline,
    onlineUsers,
  }
}

export default usePresenceDetector
