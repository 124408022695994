import React from 'react'

import GradientText from 'components/gradient-text'
import RoundedBottomContainer from './rounded-bottom-container'

const AlreadyRegisteredStatus: React.FC = () => {
  return (
    <RoundedBottomContainer>
      <div className="flex flex-col">
        <GradientText className="font-avenir-next text-inky text-2xl font-normal self-center">
          Looks like we&apos;ve already captured your information and
          you&apos;ve been registered!
        </GradientText>
        <p className="my-4 px-0 sm:px-12">
          We look forward to having you join us on September 14th.{' '}
          <b>
            {' '}
            You will receive a confirmation calendar invite from
            ThoughtCAST@kp.org prior to the event.
          </b>{' '}
          This is an invite-only event and virtual seating is limited. Please
          send questions or cancellations to{' '}
          <a
            href="mailto:ThoughtCAST@kp.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            ThoughtCAST@kp.org
          </a>{' '}
          .
        </p>
      </div>
    </RoundedBottomContainer>
  )
}

export default AlreadyRegisteredStatus
