import * as shared from 'pericles-shared'
import { ActionState } from 'context/sign-in'

const sendSignInMagicLink = (
  email: string,
  actionState: ActionState = 'register',
  isRegisteredAlready = false
) => {
  const { origin } = window.location
  const url = `${origin}/check_sign_in?e=${email}&action_state=${actionState}&previously_registered=${isRegisteredAlready}`

  const actionCodeSettings = { url, handleCodeInApp: true }
  return shared.firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
}

export default sendSignInMagicLink
