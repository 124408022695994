import React, { useState, useEffect } from 'react'

type RadioInputOptionsProps = {
  label: string
  value: string
  isDefault?: boolean
}

type RadioInputProps = {
  label: string
  radioOptions: RadioInputOptionsProps[]
  id: string
  onValidValue?: (value: string) => void
}

const RadioInput: React.FC<RadioInputProps> = (props) => {
  const { id, label, radioOptions, onValidValue } = props
  const [selectedRadio, setSelectedRadio] = useState<string | undefined>()

  useEffect(() => {
    const defaultRadioValue = radioOptions.filter((radio) => {
      return radio.isDefault === true
    })

    setSelectedRadio(defaultRadioValue[0].value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerOnSelectRadio = (radioValue: string) => {
    if (onValidValue) {
      onValidValue(radioValue)
    }
    setSelectedRadio(radioValue)
  }

  return (
    <div className="flex flex-col mb-16 mr-6">
      <label
        htmlFor={id}
        className="font-avenir-next text-dolphin text-lg mb-4"
      >
        {label}
      </label>
      <div className="flex w-input-width">
        {radioOptions.map((radio) => {
          const { label: radioLabel, value: radioValue } = radio

          return (
            <div
              onClickCapture={() => handlerOnSelectRadio(radioValue)}
              key={`${radioValue}wrapper`}
              className="flex items-center mr-8"
            >
              <div className="mr-2 w-radio-circle-area h-radio-circle-area border border-dolphin rounded-full flex">
                {selectedRadio === radioValue ? (
                  <span className="block rounded-full bg-mid-blue w-inner-radio-circle-area h-inner-radio-circle-area m-auto self-center" />
                ) : (
                  <span />
                )}
              </div>
              <label
                key={`${radioValue}label`}
                htmlFor={id}
                className="font-avenir-next text-dolphin text-lg mt-1"
              >
                {radioLabel}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioInput
