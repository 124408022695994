import CurrentEventContext from 'context/event'
import { useEffect, useState } from 'react'
import useEventDocumentInfo from './use-event-document-info'

const LFL_DOMAIN = 'leftfieldlabs.com'

const useIsAllowedDomain = (email: string) => {
  const emailPatternValidation = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,3}$/
  const isValidEmail = email.match(emailPatternValidation)
  const eventId = CurrentEventContext.useContainer()
  const { domain, filterDomain } = useEventDocumentInfo(eventId!.id)

  const [userDomain, setUserDomain] = useState(
    isValidEmail ? email.split('@')[1] : ''
  )
  const [isAllowed, setIsAllowed] = useState(
    !filterDomain ? true : userDomain === domain || userDomain === LFL_DOMAIN
  )

  useEffect(() => {
    if (isValidEmail) {
      setUserDomain(email.split('@')[1])
    }
  }, [email, isValidEmail])

  useEffect(() => {
    setIsAllowed(
      !filterDomain ? true : userDomain === domain || userDomain === LFL_DOMAIN
    )
  }, [domain, filterDomain, userDomain])

  return isAllowed
}

export default useIsAllowedDomain
