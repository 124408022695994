import React, { useState } from 'react'
import Button from 'components/button'
import RoundedBottomContainer from './rounded-bottom-container'
import NotRoundedBottomContainer from './not-rounded-bottom-container'

interface ErrorStatusProps {
  errorMessage: string
  bottomRounded?: boolean
  confirmButtonLabel?: string
  onClickConfirm?: () => void
}

const ErrorStatus: React.FC<ErrorStatusProps> = ({
  errorMessage,
  bottomRounded,
  confirmButtonLabel,
  onClickConfirm,
}) => {
  const [show, setShow] = useState(true)

  const handlerOnClickConfirm = () => {
    setShow(false)
    if (onClickConfirm) {
      onClickConfirm()
    }
  }

  return (
    <>
      {show && (
        <>
          {bottomRounded ? (
            <RoundedBottomContainer>
              <div className="flex flex-col items-center">
                <p className="px-0 sm:px-12 text-red">{errorMessage}</p>
                {confirmButtonLabel && (
                  <div className="mt-4">
                    <Button
                      onClick={handlerOnClickConfirm}
                      color="blue"
                      variant="secondary"
                    >
                      {confirmButtonLabel}
                    </Button>
                  </div>
                )}
              </div>
            </RoundedBottomContainer>
          ) : (
            <NotRoundedBottomContainer>
              <div className="flex flex-col items-center">
                <p className="px-0 sm:px-12 text-red">{errorMessage}</p>
                {confirmButtonLabel && (
                  <div className="mt-4">
                    <Button
                      onClick={handlerOnClickConfirm}
                      color="blue"
                      variant="secondary"
                    >
                      {confirmButtonLabel}
                    </Button>
                  </div>
                )}
              </div>
            </NotRoundedBottomContainer>
          )}
        </>
      )}
    </>
  )
}

export default ErrorStatus
