// TODO: Implement api
import * as shared from 'pericles-shared'
import * as types from 'pericles-types'
// export const register = shared.callFunction<
//   types.SignUpInput,
//   types.SignUpResponse
// >('register')

// export const authenticate = shared.callFunction<
//   types.AuthenticateInput,
//   types.AuthenticateResponse
// >('authenticate')

export const addUsersToWhitelist = shared.callFunction<
  types.AddUserToWhitelistInput,
  types.AddUserToWhitelistResponse
>('addUsersToWhitelist')

export const addAdminRole = shared.callFunction<
  types.AddAdminRoleInput,
  types.AddAdminRoleResponse
>('addAdminRole')

export const requestAccessToWhiteList = shared.callFunction<
  types.RequestAccessToWhitelistInput,
  types.DefaultResponse
>('requestAccessToWhiteList')

export const checkWhitelistAccessStatus = shared.callFunction<
  types.CheckWhitelistAccessStatusInput,
  types.CheckWhitelistAccessStatusResponse
>('checkWhitelistAccessStatus')

export const getBasicWhitelistUserInfo = shared.callFunction<
  types.GetBasicWhitelistUserInfoInput,
  types.GetBasicWhitelistUserInfoResponse
>('getBasicWhitelistUserInfo')
