enum MainFormTypes {
  None = 'None',
  RegistrationForm = 'RegistrationForm',
  FinishSignupForm = 'FinishSignupForm',
  SignupCompleteForm = 'SignupCompleteForm',
  SuccessfullyLoggedInForm = 'SuccessfullyLoggedInForm',
  RegisteredAndNotInWhitelistForm = 'RegisteredAndNotInWhitelistForm',
  RegisteredAndRequestAccessForm = 'RegisteredAndRequestAccessForm',
  LiveAndLoggedOutForm = 'LiveAndLoggedOutForm',
  LiveAndLoggedInWithoutAccessForm = 'LiveAndLoggedInWithoutAccessForm',
  LiveAndLoggedInWithAccessForm = 'LiveAndLoggedInWithAccessForm',
  Registered = 'Registered',
  Preloader = 'Preloader',
}

export default MainFormTypes
