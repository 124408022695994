import React, { memo, useContext, useState, useEffect } from 'react'

import * as types from 'pericles-types'
import { Loader } from 'pericles-shared'
import { PollIcon } from 'components/icons'
import PollContext from 'context/poll'
import Button from 'components/button'
import { postVote } from 'api/polls'
import { setPollAnalytics } from 'api/publish'
import UserService from 'services/user-service'

interface PollOptionAverageProps {
  readonly label: string
  readonly average: number | string
}

const PollOptionAverage: React.FC<PollOptionAverageProps> = ({
  label,
  average,
}) => {
  return (
    <div className="flex flex-col w-full mt-6">
      <p
        className="font-avenir-next text-inky font-semibold text-xs"
        style={{ marginBottom: '4px' }}
      >
        {label}
      </p>
      <span
        className="bg-mid-teal h-rounded-input-h rounded-xl flex items-center transition"
        style={{
          transition: 'width .5s',
          width: `${average >= 14 ? average : 14}%`,
        }}
      >
        <span className="font-avenir-next text-white font-semibold m-3">
          {average}%
        </span>
      </span>
    </div>
  )
}

interface PollOptionProps {
  readonly label: string
  readonly selected?: boolean
  readonly onClick?: () => void
}

const PollOption: React.FC<PollOptionProps> = ({
  label,
  selected,
  onClick,
}) => {
  return (
    <div className="flex flex-col w-full mt-6">
      <span
        onClickCapture={onClick}
        aria-label={`Option: ${label}`}
        className={`outline-none cursor-pointer rounded-xl flex items-center w-full hover:bg-mid-teal hover:bg-opacity-25 ${
          selected ? 'bg-mid-teal' : 'border-mid-teal border-3'
        }`}
      >
        <span
          className={`px-4 py-3 font-avenir-next font-semibold text-center m-auto ${
            selected ? 'text-white' : 'text-mid-teal'
          }`}
        >
          {label}
        </span>
      </span>
    </div>
  )
}

const Poll: React.FC = memo(() => {
  const { pollData, optionsAverage } = useContext(PollContext)
  const [selectedOptions, setSelectedOptions] = useState<types.PollOptions[]>()
  const [lastPollId, setLastPollId] = useState('')
  const [status, setStatus] = useState<
    'pending' | 'in_progress' | 'complete' | 'done'
  >('pending')
  const userService = UserService.getInstance()
  const { user } = userService

  useEffect(() => {
    if (pollData && window.localStorage.getItem(pollData.pollId)) {
      setStatus('done')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const checkPoll = async () => {
      if (pollData && pollData.pollId !== lastPollId) {
        setSelectedOptions([])
        setLastPollId(pollData.pollId)
        if (window.localStorage.getItem(pollData.pollId)) {
          setStatus('done')
        } else {
          setStatus('pending')
        }
      }
    }

    checkPoll()
  }, [lastPollId, pollData])

  const handlerSelectOption = (optionData: types.PollOptions) => {
    let updatedSelectedOptions: types.PollOptions[]

    if (selectedOptions) {
      if (selectedOptions.indexOf(optionData) > -1) {
        updatedSelectedOptions = selectedOptions.filter((currentOption) => {
          return currentOption.optionId !== optionData.optionId
        })
        setSelectedOptions(updatedSelectedOptions)
      } else {
        updatedSelectedOptions = [...selectedOptions, optionData]
        setSelectedOptions(updatedSelectedOptions)
      }
    } else {
      updatedSelectedOptions = [optionData]
      setSelectedOptions(updatedSelectedOptions)
    }
  }

  const handlerOnSubmit = () => {
    const sendVotes = async () => {
      if (selectedOptions && pollData) {
        setStatus('in_progress')
        const asyncVotesToPost: Promise<types.DefaultResponse>[] = []
        const asyncAnalyticsPollsToPost: Promise<types.DefaultResponse>[] = []

        selectedOptions.forEach((option) => {
          asyncAnalyticsPollsToPost.push(
            setPollAnalytics({
              eventId: 'event2',
              firstName: user.firstName!,
              lastName: user.lastName!,
              email: user.fbUser?.email!,
              question: pollData.question,
              answer: option.answer,
            })
          )
          asyncVotesToPost.push(
            postVote({
              eventId: 'event2',
              pollId: pollData.pollId,
              optionId: option.optionId,
            })
          )
        })

        await Promise.all(asyncVotesToPost)
        await Promise.all(asyncAnalyticsPollsToPost)

        window.localStorage.setItem(pollData.pollId, 'done')
        setStatus('done')
      }
    }

    sendVotes()
  }

  return (
    <div className="flex flex-col bg-light-graphite h-full xl:rounded-r-lg xl:rounded-l-lg">
      <div
        className="h-chat-h-mobile p-4 xl:w-chat-tab-w xl:max-h-poll-max-desktop overflow-auto"
        style={{
          height: 'fit-content',
        }}
      >
        <div className="flex">
          <PollIcon width="32" height="32" />
          <p className="font-avenir-next text-inky font-semibold text-3xl ml-4">
            Poll
          </p>
        </div>
        <h4
          className="font-avenir-next text-inky font-semibold text-lg mt-4"
          style={{
            lineHeight: '140%',
          }}
        >
          {pollData?.question}
        </h4>
        {status === 'pending' && (
          <>
            <div className="mt-4">
              {pollData?.options.map((option) => {
                return (
                  <PollOption
                    key={option.optionId}
                    onClick={() => handlerSelectOption(option)}
                    selected={
                      selectedOptions?.find(
                        (currentOption) =>
                          currentOption.optionId === option.optionId
                      ) != null
                    }
                    label={option.answer}
                  />
                )
              })}
            </div>
            <Button
              onClick={handlerOnSubmit}
              disabled={!selectedOptions || selectedOptions.length < 1}
              color="blue"
              additionalClass="w-full mt-4 flex-col"
            >
              Submit
            </Button>
          </>
        )}

        {status === 'done' && optionsAverage && (
          <div className="mt-4">
            {optionsAverage.map(({ answer, average }) => {
              return (
                <PollOptionAverage
                  key={answer}
                  label={answer}
                  average={average}
                />
              )
            })}
          </div>
        )}

        {status === 'in_progress' && (
          <div className="mt-4 py-64">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
})

export default Poll
