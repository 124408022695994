import React from 'react'

interface GroupProps {
  children: React.ReactNode
}

const Group: React.FC<GroupProps> = ({ children }) => {
  return (
    <div className="flex flex-col flex-wrap items-center sm:flex-row">
      {children}
    </div>
  )
}

export default Group
