import React from 'react'
import moment from 'moment-timezone'

interface EventDateTimeProps {
  eventStart: Date
  eventEnd: Date
  className: string
  style?: object
}

const EventDateTime: React.FC<EventDateTimeProps> = ({
  eventStart,
  eventEnd,
  className,
  style,
}) => {
  const start = moment(eventStart)
  const end = moment(eventEnd)
  const diff = start.diff(end, 'days')

  // If multi-day event
  if (diff) {
    const startDate = start.format('MMMM DD')
    const endDate = end.format('MMMM DD, YYYY')

    return (
      <div className={className} style={style}>
        <span>{startDate}</span> - <span>{endDate}</span>
      </div>
    )
  }

  // If single day event
  const date = start.format('MMMM DD, YYYY')
  const startTime = start.format('hh:mma')
  const endTime = end.format('hh:mma z')
  const timezone = moment.tz(moment.tz.guess()).format('z')

  return (
    <div className={className} style={style}>
      <span className="font-normal text-base font-semibold sm:text-2xl">
        {date}
      </span>{' '}
      <svg
        className="mx-6"
        width="1"
        height="33"
        viewBox="0 0 1 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.500015"
          y1="2.18557e-08"
          x2="0.500014"
          y2="33"
          stroke="#90CEF1"
        />
      </svg>
      <span className="font-normal text-base font-semibold sm:text-2xl">
        {startTime}-{endTime} {timezone}
      </span>
    </div>
  )
}

export default EventDateTime
