import React from 'react'

const StarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color } = props
  return (
    <svg
      {...props}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3333 1L44.6333 21.8667L67.6667 25.2333L51 41.4667L54.9333 64.4L34.3333 53.5667L13.7333 64.4L17.6667 41.4667L1 25.2333L24.0333 21.8667L34.3333 1Z"
        stroke={color ?? '#0078B3'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StarIcon
