import React, { useState, useEffect } from 'react'
import { useSingletonStatus } from 'reactive-singleton'

import CurrentEventContext from 'context/event'
import useEventProperties from 'hooks/use-event-properties'
import PreEvent from './states/pre-event'
import PostEvent from './states/post-event'
import UserService from '../services/user-service'

const Home: React.FC = () => {
  const event = CurrentEventContext.useContainer()
  const { eventState } = useEventProperties(event!.id)
  const [eventView, setEventView] = useState(<></>)
  const userServiceStatus = useSingletonStatus(UserService) === 'ready'

  useEffect(() => {
    if (eventState && userServiceStatus) {
      switch (eventState.currentState) {
        case 'pre':
        case 'live':
          setEventView(<PreEvent />)
          break
        case 'post':
          setEventView(<PostEvent />)
          break
        default:
          break
      }
    }
  }, [eventState, userServiceStatus])

  return eventView
}

export default Home
