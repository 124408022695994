import React from 'react'
import { Submit } from 'components/form'
import { useNavigate } from 'react-router-dom'

const WatchNowForm: React.FC = () => {
  const navigate = useNavigate()

  const onWatchSelect = () => {
    navigate('/watch')
  }

  const submit = <Submit value="Join the Event" onClick={onWatchSelect} />

  return (
    <div className="mb-8 bg-white w-full flex flex-col justify-between items-center pb-12 pt-8 shadow-finish-signup-form-preloader rounded-b-lg">
      <h1 className="text-deep-blue font-avenir-next text-4xl text-center mb-4">
        ThoughtCAST is live!
      </h1>
      {submit}
    </div>
  )
}

export default WatchNowForm
