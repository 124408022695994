import * as types from 'pericles-types'
import { StreamChat } from 'stream-chat'

import {
  CreateChatUserTokenInput,
  CreateChatUserTokenResponse,
} from 'pericles-types'
import * as shared from 'pericles-shared'

const streamChatApiKey = process.env.REACT_APP_STREAM_CHAT_API_KEY as string
if (!streamChatApiKey) {
  throw new Error(
    'You must set a value for REACT_APP_STREAM_CHAT_API_KEY in the environment!'
  )
}

const client = new StreamChat(streamChatApiKey)

let isUserSet = false

/**
 * Set user to the chat
 * @param userId user id provided by firebase auth login
 * @param name user name
 * @param image user profile picture
 * @param chatUserToken user token generated by createChatUserToken method
 */
export const setUser = (
  userId: string,
  name: string,
  image: string,
  chatUserToken: string
) => {
  if (!isUserSet) {
    isUserSet = true
    client.setUser(
      {
        id: userId,
        name,
        image,
      },
      chatUserToken
    )
  }
}

/**
 * Create user token | Server side. This is the only way for create token
 * @param userId user id provided by firebase auth login
 */
export const createChatUserToken = shared.callFunction<
  CreateChatUserTokenInput,
  CreateChatUserTokenResponse
>('createChatUserToken')

export const getClient = () => client

export const getChannel = (eventName: string) =>
  client.channel('livestream', eventName)

export const createQuestion = shared.callFunction<
  types.CreateQuestionInput,
  types.CreateQuestionResponse
>('createQuestion')
