import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import * as shared from 'pericles-shared'

import { USER_EMAIL_STORAGE_KEY } from 'components/main-form-display/constants'
import ModalContext from 'context/modal'
import CurrentEventContext from 'context/event'
import sendSignInMagicLink from 'utils/auth/send-sign-in-magic-link'
import SignInContext, { ActionState } from 'context/sign-in'
import useEventProperties from 'hooks/use-event-properties'

const { firebase } = shared

const CheckSignin: React.FC = () => {
  const { showModal } = useContext(ModalContext)
  const {
    setActionState,
    setEmailUrlParam,
    setPreviouslyRegistered,
  } = useContext(SignInContext)
  const event = CurrentEventContext.useContainer()
  const { eventState } = useEventProperties(event!.id)
  const navigate = useNavigate()

  const sendSignInLink = async (email: string, actionState: ActionState) => {
    sendSignInMagicLink(email, actionState)
      .then(() => {
        showModal({
          description:
            'Please check your email for confirmation to complete the login process.',
        })
      })
      .catch(() => {
        showModal({
          title: 'Something went wrong!',
          description:
            'Please request a new sign in link to finish registering again.',
          confirmText: 'Request sign in link',
          onConfirm: () => {
            sendSignInLink(email, actionState)
          },
        })
      })
  }

  // Complete the Sign In process: When user clicks on the login link
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const checkSignIn = () => {
      const storedUserEmail =
        window.localStorage.getItem(USER_EMAIL_STORAGE_KEY) ??
        urlParams.get('e')

      setEmailUrlParam(urlParams.get('e') ?? '')

      const actionState = (urlParams.get('action_state') as ActionState) ?? ''
      setActionState(actionState)

      const previouslyRegisteredParam = urlParams.get('previously_registered')
      setPreviouslyRegistered(
        previouslyRegisteredParam ? previouslyRegisteredParam === 'true' : false
      )

      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        firebase
          .auth()
          .signInWithEmailLink(storedUserEmail as string, window.location.href)
          .then(() => {
            window.localStorage.removeItem(USER_EMAIL_STORAGE_KEY)

            if (eventState?.currentState === 'live') {
              navigate('/')
            } else {
              navigate('/#registration')
            }
          })
          .catch(() => {
            showModal({
              title: 'Something went wrong!',
              description:
                'The link you are trying to reach has expired. Please request a new sign in link to finish registering.',
              confirmText: 'Request sign in link',
              onConfirm: () => {
                sendSignInLink(storedUserEmail as string, actionState)
              },
            })
          })
      } else {
        showModal({
          title: 'Email not found!',
          description:
            'Your email is not present in our system. Please return to the home page to log in.',
          confirmText: 'Return Home',
          onConfirm: () => {
            navigate('/')
          },
        })
      }
    }

    checkSignIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-screen h-screen flex items-center bg-white">
      <shared.Loader />
    </div>
  )
}

export default CheckSignin
