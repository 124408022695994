import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

const useCurrentEvent = () => {
  const { data: event } = shared.useDocument<types.InstanceProperties>(
    'instanceProperties/default',
    {
      listen: true,
    }
  )

  return event?.currentEvent
}

const CurrentEventContext = shared.createContainer(useCurrentEvent)

export default CurrentEventContext
