import React from 'react'
import gradientTextStyle from 'utils/gradient-text-style'

interface GradientTextProps {
  className?: string
  children: React.ReactNode
  variant?: 'primary' | 'secondary'
}

const GradientText: React.FC<GradientTextProps> = ({
  className,
  children,
  variant = 'primary',
}) => {
  const variantClass =
    variant === 'primary'
      ? 'background-image-title'
      : 'background-image-title-2'

  return (
    <h4
      className={`text-black not-italic ${variantClass} font-avenir-next ${className}`}
      style={gradientTextStyle}
    >
      {children}
    </h4>
  )
}

export default GradientText
