import React from 'react'

import 'pericles-shared/dist/styles/main.scss'
import * as shared from 'pericles-shared'
import { Routes, Route, Navigate } from 'react-router-dom'

import CurrentUserContext from 'context/user'
import CurrentEventProvider from 'context/event'
import PrivacyPolicy from 'views/privacy-policy'
import TermsOfUse from 'views/terms-of-use'
import LiveEvent from 'views/states/live-event'
import LogOut from 'views/auth/log-out'
import { ModalProvider } from 'context/modal'
import { SignInProvider } from 'context/sign-in'
import Home from './views/home'
import CheckSignin from './views/auth/check-sign-in'

const App: React.FC = () => {
  return (
    <>
      <div className="flex-auto flex flex-col relative z-5 background-image-default-background">
        <shared.ErrorBoundary>
          <React.Suspense fallback={<shared.Loader background="white" />}>
            <CurrentEventProvider.Provider>
              <CurrentUserContext.Provider>
                <SignInProvider>
                  <ModalProvider>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="*" element={<Navigate to="/" />} />
                      <Route path="/watch" element={<LiveEvent />} />
                      <Route path="/check_sign_in" element={<CheckSignin />} />
                      <Route
                        path="/privacy_policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route path="/terms_of_use" element={<TermsOfUse />} />
                      <Route path="/log_out" element={<LogOut />} />
                    </Routes>
                  </ModalProvider>
                </SignInProvider>
              </CurrentUserContext.Provider>
            </CurrentEventProvider.Provider>
          </React.Suspense>
        </shared.ErrorBoundary>
      </div>
    </>
  )
}

export default App
