import React from 'react'

import footerLogo from 'assets/svg/kaiser-permanente-logo.svg'
import SimpleLink from './simple-link'

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col flex-wrap bg-inky h-auto py-10 px-12 items-start justify-start lg:flex-row lg:justify-between lg:items-center">
      <a
        href="https://about.kaiserpermanente.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={footerLogo}
          alt="Kaiser Permanente Logo"
          className="mb-12 lg:mb-0"
        />
      </a>
      <div className="flex flex-col justify-between lg:pt-3 lg:w-1/3 lg:flex-row">
        <SimpleLink
          href="mailto:ThoughtCAST@kp.org"
          target="_blank"
          rel="noopener noreferrer"
          className="mb-8 lg:mb-0"
        >
          Contact us
        </SimpleLink>
        <SimpleLink
          href="/privacy_policy"
          target="blank_"
          className="mb-8 lg:mb-0"
        >
          Privacy Policy
        </SimpleLink>
        <SimpleLink
          href="/terms_of_use"
          target="blank_"
          className="mb-8 lg:mb-0"
        >
          Terms of Use
        </SimpleLink>
      </div>
    </footer>
  )
}

export default Footer
