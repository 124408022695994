import React from 'react'
import DocumentLayout from 'layouts/document-layout'
import {
  Paragraph,
  Subtitle,
  List,
  ListItem,
  Info,
  Anchor,
} from 'components/document'

const PrivacyPolicy: React.FC = () => {
  const textContent = (
    <>
      <Subtitle>Introduction</Subtitle>
      <Paragraph>
        Kaiser Permanente provides the ThoughtCAST internet-based event platform
        in partnership with Left Field Labs.
      </Paragraph>
      <Paragraph>
        The ThoughtCAST event platform allows our employee and other users to
        participate in a live webcast event, use of chat functionality and to
        provide responses to polls.
      </Paragraph>
      <Paragraph>
        This Privacy Statement describes how Kaiser Permanente collects and uses
        the personal information you provide on, and other information that is
        collected from your use of the Website. It also describes the choices
        available to you regarding our use of your personal information and how
        you can access and update this information.
      </Paragraph>
      <Paragraph>
        Personal information means information that is individually
        identifiable. Information that has been de-identified by Kaiser
        Permanente or others is no longer personal information and is not
        covered by the terms of this Privacy Statement.
      </Paragraph>
      <Paragraph>
        Kaiser Permanente is committed to protecting the privacy of the users of
        the ThoughtCAST event platform. We will use and disclose your personal
        information as stated in this Privacy Statement.
      </Paragraph>

      <Subtitle>ThoughtCAST event platform Privacy Statement</Subtitle>
      <Paragraph>
        Our use and disclosure of an individual&apos;s personal information is
        limited as required by state and federal law. We do not sell or rent
        personal information about visitors to the ThoughtCAST event platform.
      </Paragraph>

      <Subtitle>Security</Subtitle>
      <Paragraph>
        The ThoughtCAST event platform has security measures in place that are
        intended to help protect against the loss, misuse, unauthorized access
        or alteration of information under our control both during transmission
        and once the information is received. These measures include encryption
        of data using the Secure Socket Layer (SSL) system, and using a secured
        messaging service when we send your personal information electronically
        to the Website. Despite these measures, the confidentiality of any
        communication or material transmitted to or from us via the Website by
        Internet, text message or email cannot be guaranteed. At your
        discretion, you may contact us at the mailing address or telephone
        number listed in the &quot;Questions, complaints, and contacts&quot;
        section at the end of this Privacy Statement.
      </Paragraph>

      <Subtitle>Revisions to the Privacy Statement</Subtitle>
      <Paragraph>
        We may revise this Privacy Statement from time to time as we add new
        features or modify the way in which we manage information, or as laws
        change that may affect our services. If we make material changes to our
        Privacy Statement, we will post notice of this on the ThoughtCAST event
        platform prior to the changes becoming effective. Any revised Privacy
        Statement will apply both to information we already have about you at
        the time of the change, and any personal information created or received
        after the change takes effect. We include a version number on this
        Privacy Statement consisting of the date (year, month, and day) it was
        last revised. We encourage you to periodically reread this Privacy
        Statement, to see if there have been any changes to our policies that
        may affect you.
      </Paragraph>

      <Subtitle>ThoughtCAST event platform Visitor Data</Subtitle>
      <Paragraph>
        In addition to web logs, described below, Kaiser Permanente routinely
        gathers data on website activity, such as how many people visit the
        site, the web pages they visit, where they come from, how long they
        stay, etc. The data is collected on an aggregate basis, which means that
        no personally identifiable information is associated with the data. This
        data helps us and our vendor partner Left Field Labs improve our content
        and overall usage. The information is not shared with other
        organizations for their independent use.
      </Paragraph>
      <Paragraph>
        The Websites do not honor a browser’s signal or header request not to
        track the user&apos;s activity.
      </Paragraph>

      <Subtitle>
        Collecting and using and disclosing personal information
      </Subtitle>
      <Paragraph>
        Except as disclosed in this Privacy Statement, we do not collect any
        personally identifiable information about visitors to the Website. The
        policies, sources, uses and disclosures of information are outlined in
        Sections 1 through 13 that follow:
      </Paragraph>
      <Paragraph>1. Information Collection Use</Paragraph>
      <Paragraph>
        We collect the following personal information from you:
      </Paragraph>
      <List>
        <ListItem>
          user’s first name, last name, location, and job title
        </ListItem>
        <ListItem>user email addresses</ListItem>
      </List>
      <Paragraph>We use and disclose this information to:</Paragraph>
      <List>
        <ListItem>register your account</ListItem>
        <ListItem>
          authenticate your account when you come to the event site and provide
          you access to the event platform
        </ListItem>
      </List>

      <Paragraph>2. Web logs</Paragraph>
      <Paragraph>
        As is true of most websites, we gather certain information
        automatically. We maintain standard Web logs that record data about all
        visitors and customers who use the ThoughtCAST event platform Website
        and we store this information for no longer than reasonably useful to
        carry out its legitimate business purpose, or as legally required. These
        logs may contain the Internet domain from which you access the site
        (such as aol.com, abc.org, etc.); the IP address which is automatically
        assigned to your computer when you get on the Internet (a static IP
        address may be identifiable as being connected to you, while a dynamic
        address is usually not identifiable); the type of browser and operating
        system you use; the date and time you visited; the pages or mobile
        screens you viewed; and the address of the website you linked from, if
        any. If you sign on to the Website, our Web logs will also contain an
        individual identifier and show the services you have accessed.
      </Paragraph>
      <Paragraph>
        All Web logs are stored securely, and may only be accessed by Kaiser
        Permanente employees or designees on a need-to-know basis for a specific
        purpose. Kaiser Permanente uses Web log information to help us design
        our web experiences, to identify popular features, to resolve user,
        hardware, and software problems, to make the Website more useful to
        visitors and for security purposes.
      </Paragraph>
      <Paragraph>3. Internet cookies</Paragraph>
      <Paragraph>
        We and our partners may place Internet &quot;cookies&quot; or similar
        technologies on the computer hard drives of visitors to the Website.
        Information we obtain helps us to tailor our site to be more helpful and
        efficient for our visitors. The cookie consists of a unique identifier.
        that does not contain information about your health history. We use two
        types of cookies, &quot;session&quot; cookies and &quot;persistent&quot;
        cookies, along with other similar technologies.
      </Paragraph>
      <Paragraph>
        A session cookie is temporary, and expires after you end a session and
        close your web or app browser. We use session cookies to help customize
        your experience on our site, maintain your signed-on status as you
        navigate through our features, and to track your &quot;click path&quot;
        through our Web pages.
      </Paragraph>
      <Paragraph>
        Persistent cookies remain on your hard drive after you&apos;ve exited
        from our website, and we use them to enhance your experience.
      </Paragraph>
      <Paragraph>
        You may have software on your computer that will allow you to decline or
        deactivate Internet cookies, but if you do so, some features of the
        Website may not work properly for you. For instructions on how to remove
        cookies from your hard drive, go to your browser&apos;s website for
        detailed instructions. In addition, further information regarding
        cookies may be available on other websites or from your Internet service
        provider. Safari, Chrome, Firefox, Internet Explorer and iOS browsers
        are commonly used browsers.
      </Paragraph>
      <Paragraph>4. Web beacons</Paragraph>
      <Paragraph>
        We may also occasionally use &quot;Web beacons&quot; (also known as
        &quot;clear gifs,&quot; &quot;Web bugs,&quot; &quot;1-pixel gifs,&quot;
        etc.) that allow us to collect non-personal information about your
        response to our email communications, and for other purposes. Web
        beacons are tiny images, placed on a Web page or email, that can tell us
        if you&apos;ve gone to a particular area on our website. For example, if
        you&apos;ve given us permission to send you emails, we may send you an
        email urging you to use a certain feature on our website. If you do
        respond to that email and use that feature, the Web beacon will tell us
        that our email communication with you has been successful. We do not
        collect any personal health information with a Web beacon, and do not
        link Web beacons with any other personal health information you&apos;ve
        given us.
      </Paragraph>
      <Paragraph>
        Since Web beacons are used in conjunction with persistent cookies
        (described above), if you set your browser to decline or deactivate
        cookies, Web beacons cannot function.
      </Paragraph>
      <Paragraph>
        If you wish to not have this information used for the purpose of serving
        you targeted ads, you may opt out. Please note this does not opt you out
        of being served advertising. You may continue to receive generic
        non-targeted ads.
      </Paragraph>
      <Paragraph>5. Emails</Paragraph>
      <Paragraph>
        Kaiser Permanente uses a third-party vendor to help us manage some of
        our email and text messaging communications with you. While we do supply
        these vendors with email addresses or mobile telephone numbers of those
        we wish them to contact, your email address or mobile telephone number
        is never used for any purpose other than to communicate with you on our
        behalf. When you click on a link in an email, you may temporarily be
        redirected through one of the vendor&apos;s servers (although this
        process will be invisible to you) which will register that you&apos;ve
        clicked on that link, and have visited our Websites or KP Mobile
        Application. Kaiser Permanente never shares any information, other than
        your email address or telephone number, with our third-party email and
        text messaging vendors, which may only share this information with its
        authorized subcontractors.
      </Paragraph>
      <Paragraph>
        Even if you have given us permission to send emails or text messages to
        you, you may revoke that permission at any time by following the
        &quot;unsubscribe&quot; information at the bottom of the email or by
        replying “Stop” to any text message you receive from us.
      </Paragraph>
      <Paragraph>6. Evaluation and quality improvement</Paragraph>
      <Paragraph>
        We will periodically ask users to complete surveys asking about their
        experiences with features of the Website and experience of event(s)
        hosted on the Website. Our surveys may ask visitors for demographic
        information such as age, gender, and education, but will not request
        that users provide specific information about any medical condition. We
        use survey information for evaluation and quality improvement purposes,
        including helping Kaiser Permanente to improve information and services
        offered through the Website. In addition, users giving feedback may be
        individually contacted for follow-up due to concerns raised during the
        course of such evaluation. Demographic information and Web log data may
        be stored for future evaluation and quality improvement activities.
      </Paragraph>
      <Paragraph>7. Messages</Paragraph>
      <Paragraph>
        Comments or questions sent to us using email will be shared with Kaiser
        Permanente staff who are most able to address your concerns. We will
        archive your messages once we have made our best effort to provide you
        with a complete and satisfactory response.
      </Paragraph>
      <Paragraph>8. Data integrity and correction</Paragraph>
      <Paragraph>
        Requests to view and correct personal information from the Website may
        be submitted using the contact information in the &quot;Questions,
        complaints, and contacts&quot; section below.
      </Paragraph>
      <Paragraph>
        If your personal information changes, or to update or request deletion
        of information collected on our Website, or if you believe a third party
        has provided us with your personal information and you would like to
        request that it be edited or removed from our database, please use the
        contact information in the “Questions, complaints, and contacts” section
        below. We will respond to all access requests within 30 days.
      </Paragraph>
      <Paragraph>9. Children</Paragraph>
      <Paragraph>
        We do not knowingly collect personally identifiable information from
        children under the age of 13. If Kaiser Permanente is made aware of
        collecting information from a child under 13 we will delete this
        information.
      </Paragraph>
      <Paragraph>10. Disclosures</Paragraph>
      <Paragraph>
        We may disclose personal information to any person performing audit,
        legal, operational, or other services for us. We will use information
        which does not identify the individual for these activities whenever
        reasonably possible. Information disclosed to vendors or contractors for
        operational purposes may not be re-disclosed to others by such a vendor
        or contractor, except as permitted by KP and applicable law.
      </Paragraph>
      <Paragraph>
        We may also disclose your personal information to third parties who
        provide services on our behalf to help with our business activities.
        These companies are authorized to use your personal information only as
        necessary to provide these services to us.
      </Paragraph>
      <Paragraph>These services may include:</Paragraph>
      <List>
        <ListItem>providing customer service</ListItem>
        <ListItem>sending marketing communications</ListItem>
        <ListItem>providing cloud computing infrastructure</ListItem>
      </List>
      <Paragraph>We may also disclose your personal information:</Paragraph>
      <List>
        <ListItem>
          as required by law, such as to comply with a subpoena, or similar
          legal process
        </ListItem>
        <ListItem>
          when we believe in good faith that disclosure is necessary to protect
          our rights, protect you or others from threats of imminent harm,
          investigate fraud or other activity in violation of the law, or
          respond to government requests (including to meet national security or
          law enforcement requirements)
        </ListItem>
        <ListItem>
          to protect the security and reliability of the Website
        </ListItem>
        <ListItem>
          if Kaiser Permanente is involved in a merger, acquisition, or sale of
          all or a portion of its assets. You will be notified via email and/or
          a prominent notice on our Website of any change in ownership or uses
          of your personal information, as well as any choices you may have
          regarding your personal information
        </ListItem>
        <ListItem>
          to any other third party with your prior consent to do so
        </ListItem>
      </List>
      <Paragraph>11. Opt out</Paragraph>
      <Paragraph>
        If a user makes a request to receive information in an ongoing manner
        through the Website by providing an email address the user may request
        to discontinue future mailings. Similarly, if you receive information
        about a Kaiser Permanente service through email, you may make a request
        to discontinue receiving similar messages in the future. All such
        materials sent to you by email or text message will contain information
        about how to opt out.
      </Paragraph>
      <Paragraph>12. Data retention</Paragraph>
      <Paragraph>
        We will retain your information for as long as your account is active or
        as needed to provide you services. We will retain and use your
        information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements.
      </Paragraph>
      <Paragraph>13. Social media</Paragraph>
      <Paragraph>
        Our Website includes Social Media Features, such as the Facebook button.
        These Features may collect your IP address, which page you are visiting
        on our site, and may set a cookie to enable the Feature to function
        properly. Social Media Features are either hosted by a third party or
        hosted directly on our Site. Your interactions with these Features are
        governed by the privacy statement of the company providing them.
      </Paragraph>
      <Subtitle>Questions, complaints, and contacts</Subtitle>
      <Paragraph>
        If you have any questions about this Privacy Statement, our policies and
        practices concerning the Website, your rights under this statement, and
        your dealings with the Kaiser Permanente Website, you can contact Kaiser
        Permanente at the email address below:
      </Paragraph>
      <Anchor href="mailto:ThoughtCast@kp.org">ThoughtCast@kp.org</Anchor>
      <Info>Last revised: July 1, 2020.</Info>
    </>
  )
  return (
    <DocumentLayout
      title="ThoughtCAST Event Platform Privacy Statement"
      textElement={textContent}
    />
  )
}

export default PrivacyPolicy
