import React from 'react'

interface ButtonProps {
  variant?: 'primary' | 'secondary'
  size?: 'medium' | 'large' | 'small'
  color?: 'blue' | 'white'
  submit?: boolean
  disabled?: boolean
  additionalClass?: string
  onClick?: () => void | null
  children: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'medium',
  color = 'white',
  submit = false,
  disabled = false,
  additionalClass = '',
  onClick,
}) => {
  function generateClass() {
    let className =
      'flex items-center leading-none cursor-pointer font-semibold not-italic font-avenir-next focus:outline-none disabled:text-opacity-50 border-3 '

    if (size === 'small') {
      className += 'text-base rounded-full p-3 '
    }

    if (size === 'medium') {
      className += 'text-base rounded-full px-6 py-3 '
    }

    if (size === 'large') {
      className += 'text-2xl rounded-full px-10 py-4 '
    }

    if (variant === 'primary') {
      if (color === 'white') {
        // Default
        className += 'text-deep-blue bg-white border-white '
        // Hover
        className += 'hover:bg-deep-graphite hover:border-deep-graphite '
        // Press/Tap
        className += 'focus:bg-light-blue focus:border-light-blue '
      }

      if (color === 'blue') {
        // Default
        className += 'text-white bg-mid-blue border-mid-blue '
        // Hover
        className += 'hover:bg-blue hover:border-blue '
        // Press/Tap
        className += 'focus:bg-deep-blue focus:border-deep-blue '
      }
    }

    if (variant === 'secondary') {
      if (color === 'white') {
        // Default
        className += 'text-white border-white '
        // Hover
        className += 'hover:text-light-blue hover:border-light-blue '
        // Press/Tap
        className += 'focus:text-white focus:border-white focus:bg-mid-blue '
      }

      if (color === 'blue') {
        // Default
        className += 'text-mid-blue border-mid-blue '
        // Hover
        className += 'hover:text-blue hover:border-blue '
        // Press/Tap
        className +=
          'focus:bg-blue focus:text-deep-blue focus:border-deep-blue '
      }
    }

    return className + additionalClass
  }

  if (submit) {
    return (
      <button
        type="submit"
        className={generateClass()}
        {...{ disabled, onClick }}
      >
        {children}
      </button>
    )
  }

  return (
    <button
      type="button"
      className={generateClass()}
      {...{ disabled, onClick }}
    >
      {children}
    </button>
  )
}

export default Button
