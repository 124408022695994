import * as shared from 'pericles-shared'
import {
  SetLiveEventsAnalyticsInput,
  SetPollAnalyticsInput,
  DefaultResponse,
} from 'pericles-types'

export const setLiveEventsAnalytics = shared.callFunction<
  SetLiveEventsAnalyticsInput,
  void
>('setLiveEventsAnalytics')

export const setPollAnalytics = shared.callFunction<
  SetPollAnalyticsInput,
  DefaultResponse
>('setPollAnalytics')
