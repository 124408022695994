import * as shared from 'pericles-shared'
import {
  RegisterUserInput,
  CheckRegistrationInput,
  CheckRegistrationResponse,
  GetBasicUserInfoInput,
  GetBasicUserInfoResponse,
  GetUserRegistrationInput,
  GetUserRegistrationResponse,
  IsInitialRegistrationInput,
  IsInitialRegistrationResponse,
} from 'pericles-types'

export const registerUser = shared.callFunction<RegisterUserInput, {}>(
  'registerUser'
)

export const checkRegistration = shared.callFunction<
  CheckRegistrationInput,
  CheckRegistrationResponse
>('checkRegistration')

export const getBasicUserInfo = shared.callFunction<
  GetBasicUserInfoInput,
  GetBasicUserInfoResponse
>('getBasicUserInfo')

export const getUserRegistration = shared.callFunction<
  GetUserRegistrationInput,
  GetUserRegistrationResponse
>('getUserRegistration')

/** Determines if its the first time the user registered */
export const isInitialRegistration = shared.callFunction<
  IsInitialRegistrationInput,
  IsInitialRegistrationResponse
>('isInitialRegistration')
