import { AdditionalTapButton } from 'components/chat/tab-button'
import {
  CLICK_MENU_BUTTON_HANDLER_SET,
  MENU_BUTTON_CLICKED,
  ADDITIONAL_BUTTONS_ADDED,
  SetClickMenuButtonHandlerAction,
  OnClickMenuButtonAction,
  AddAdditionalButtonsAction,
  SET_STREAM_CHAT_USER_PROPS,
  SetChatUserPropsAction,
  UpdateOnlineCounterAction,
  UPDATE_ONLINE_COUNTER,
  SetIsChatEnabledAction,
  SET_IS_CHAT_ENABLED,
} from './types'

export const setClickMenuButtonHandler = (
  handler: (buttonValue: string) => void
): SetClickMenuButtonHandlerAction => {
  return {
    type: CLICK_MENU_BUTTON_HANDLER_SET,
    payload: {
      onClickMenuButtonHandler: handler,
    },
  }
}

export const onClickMenuButton = (
  buttonValue: string
): OnClickMenuButtonAction => {
  return {
    type: MENU_BUTTON_CLICKED,
    payload: {
      buttonValue,
    },
  }
}

export const addAdditionalButtons = (
  additionalButtons: AdditionalTapButton[]
): AddAdditionalButtonsAction => {
  return {
    type: ADDITIONAL_BUTTONS_ADDED,
    payload: {
      additionalButtons,
    },
  }
}

export const setChatUserProps = (
  userId: string,
  userName: string,
  streamChatUserToken: string,
  userPhotoURL?: string
): SetChatUserPropsAction => {
  return {
    type: SET_STREAM_CHAT_USER_PROPS,
    payload: {
      userId,
      streamChatUserToken,
      userPhotoURL,
      userName,
    },
  }
}

export const updateOnlineUsersCounter = (
  usersOnlineCounter: number
): UpdateOnlineCounterAction => {
  return {
    type: UPDATE_ONLINE_COUNTER,
    payload: {
      onlineCounter: usersOnlineCounter,
    },
  }
}

export const setIsChatEnabled = (
  isEnabled: boolean
): SetIsChatEnabledAction => {
  return {
    type: SET_IS_CHAT_ENABLED,
    payload: {
      isEnabled,
    },
  }
}
