import React, { createContext, useEffect, useState } from 'react'

import * as types from 'pericles-types'
import * as shared from 'pericles-shared'
import { getPoll } from 'api/polls'
import CurrentEventContext from './event'

interface OptionsAverage {
  answer: string
  average: number
}

interface OptionProps {
  answer: string
  votes: number
  id: string
}

interface PollContextProps {
  pollData?: types.GetPollResponse
  optionsAverage?: OptionsAverage[]
}

const PollContext = createContext<PollContextProps>({})
export default PollContext

export const PollProvider: React.FC = ({ children }) => {
  const event = CurrentEventContext.useContainer()
  const [pollData, setPollData] = useState<types.GetPollResponse | null>()
  const [optionsAverage, setOptionsAverage] = useState<OptionsAverage[]>([])

  const { data: eventInfo } = shared.useDocument<types.EventDocumentInfo>(
    `events/${event!.id}`,
    {
      listen: true,
      suspense: false,
    }
  )
  const { data: pollOptionsData } = shared.useCollection<OptionProps>(
    eventInfo?.activatedPoll
      ? `events/${event!.id}/polls/${eventInfo?.activatedPoll}/options`
      : 'events',
    {
      listen: true,
    },
    { suspense: false }
  )

  useEffect(() => {
    if (eventInfo?.activatedPoll && pollOptionsData) {
      const totalVotes = pollOptionsData.reduce((votes, option) => {
        return votes + option.votes
      }, 0)

      const average: OptionsAverage[] = []
      pollOptionsData.forEach(({ answer, votes }) => {
        average.push({
          answer,
          average: Math.round((votes * 100) / totalVotes),
        })
      })

      setOptionsAverage(average)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollOptionsData])

  useEffect(() => {
    const fetchPollData = async () => {
      if (eventInfo && eventInfo.activatedPoll) {
        const { activatedPoll } = eventInfo
        const currentPollData = await getPoll({
          eventId: 'event2',
          pollId: activatedPoll,
        })
        setPollData(currentPollData)
      } else {
        setPollData(null)
      }
    }

    fetchPollData()
  }, [eventInfo])

  return (
    <PollContext.Provider
      value={{ pollData: pollData as types.GetPollResponse, optionsAverage }}
    >
      {children}
    </PollContext.Provider>
  )
}
