import React from 'react'
import NotRoundedBottomContainer from './not-rounded-bottom-container'

const EmailConfirmedStatus: React.FC = () => (
  <NotRoundedBottomContainer>
    <p className="px-0 sm:px-12">
      Thank you for confirming your email, please fill out the following form to
      finish your registration.
    </p>
  </NotRoundedBottomContainer>
)

export default EmailConfirmedStatus
