import React from 'react'

const Anchor: React.FC<React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>> = (props) => {
  const { children } = props
  return (
    <a
      {...props}
      className="my-4 text-mid-blue focus:text-deep-blue hover:text-deep-blue"
    >
      {children}
    </a>
  )
}

export default Anchor
