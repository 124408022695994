import React, { createContext, useState } from 'react'

export type ActionState = 'register' | 'login' | ''

interface SignInProps {
  userEmail: string
  emailUrlParam: string
  actionState: ActionState
  previouslyRegistered: boolean
  setUserEmail: (userEmail: string) => void
  setEmailUrlParam: (email: string) => void
  setActionState: (actionState: ActionState) => void
  setPreviouslyRegistered: (value: boolean) => void
}

const defaultProps: SignInProps = {
  userEmail: '',
  emailUrlParam: '',
  actionState: '',
  previouslyRegistered: false,
  setUserEmail: () => {
    throw new Error('setUserEmail must be defined!')
  },
  setEmailUrlParam: () => {
    throw new Error('setEmailUrlParam must be defined!')
  },
  setActionState: () => {
    throw new Error('setActionState must be defined!')
  },
  setPreviouslyRegistered: () => {
    throw new Error('setPreviouslyRegistered must be defined!')
  },
}

const SignInContext = createContext<SignInProps>(defaultProps)
export default SignInContext

export const SignInProvider: React.FC = ({ children }) => {
  const [userEmail, setUserEmail] = useState('')
  const [emailUrlParam, setEmailUrlParam] = useState('')
  const [actionState, setActionState] = useState<ActionState>('')
  const [previouslyRegistered, setPreviouslyRegistered] = useState(false)

  return (
    <SignInContext.Provider
      value={{
        userEmail,
        emailUrlParam,
        actionState,
        previouslyRegistered,
        setUserEmail,
        setEmailUrlParam,
        setActionState,
        setPreviouslyRegistered,
      }}
    >
      {children}
    </SignInContext.Provider>
  )
}
