import React from 'react'
import RoundedBottomContainer from './rounded-bottom-container'

const SuccessfullyLoggedInStatus: React.FC = () => (
  <RoundedBottomContainer>
    <p className="px-0 sm:px-12">You are now successfully logged in</p>
  </RoundedBottomContainer>
)

export default SuccessfullyLoggedInStatus
