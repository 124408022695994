import { useEffect, useState } from 'react'
import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

const useEventId = () => {
  const [eventId, setEventId] = useState('event')
  const { data } = shared.useDocument<types.InstanceProperties>(
    'instanceProperties/default',
    {
      suspense: false,
    }
  )

  useEffect(() => {
    setEventId(data?.currentEvent?.id || 'event')
  }, [data])

  return eventId
}

export default useEventId
