import React, { useEffect, useRef, useState, useContext } from 'react'
import TextArea from 'react-textarea-autosize'
import * as types from 'pericles-types'
import * as shared from 'pericles-shared'

import { SendIcon } from 'components/icons'
import ChatContext from 'context/chat'
import { createQuestion } from 'api/chat'
import useEventId from 'hooks/use-event-id'
import LoadingIcon from 'components/chat/loading-icon'

interface AdminUser extends shared.firebase.User {
  admin?: boolean | undefined
}

const QAndA = () => {
  const user: AdminUser | null | undefined = shared.AuthContext.useContainer()
  const { userId, userName = '' } = useContext(ChatContext)
  const [text, setText] = useState('')
  const [submission, setSubmission] = useState('')
  const [timeoutId, setTimeoutId] = useState<number>()
  const questionsContainer = useRef<HTMLDivElement>(null)
  const eventId = useEventId()
  const { data: questions, loading } = shared.useCollection<types.Question>(
    `events/${eventId}/questions`,
    {
      listen: true,
      ...(user?.admin ? {} : { where: ['userId', '==', userId] }),
    },
    { suspense: false }
  )

  const scrollToBottom = () => {
    if (questionsContainer.current) {
      questionsContainer.current.scrollTop =
        questionsContainer.current?.scrollHeight
    }
  }

  useEffect(scrollToBottom, [questions])

  const handleSubmit = () => {
    setSubmission(text)
    setText('')

    const id = window.setTimeout(async () => {
      await createQuestion({ text, userName })

      setSubmission('')
      // Apply seven second delay before submitting a question
    }, 5000)

    setTimeoutId(id)
  }

  const undoSubmit = () => {
    setText(submission)
    setSubmission('')
    clearTimeout(timeoutId)
  }

  const handleTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(value)
  }

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const EmptyMessage = () => (
    <div className="font-semibold text-dolphin h-full w-full flex items-center justify-center">
      Submit a question to the panel
    </div>
  )

  const SubmitMessage = () => (
    <div className="flex flex-col h-full w-full justify-center text-center px-8">
      <span className="font-semibold text-dolphin">
        Your question has been submitted! Speakers will answer questions in the
        order they were received.
      </span>
      <button
        type="button"
        className="font-semibold text-mid-blue pt-6 focus:outline-none"
        onClick={undoSubmit}
      >
        Undo
      </button>
    </div>
  )

  const renderContent = () => {
    if (loading) {
      return <LoadingIcon size={30} />
    }

    if (submission) {
      return <SubmitMessage />
    }

    if (!questions?.length) {
      return <EmptyMessage />
    }

    const sortedQuestions = questions
      ?.filter((question) => !!question.userId)
      .sort((q1, q2) => {
        return q1.submitted?.seconds - q2.submitted?.seconds
      })

    if (user?.admin) {
      return sortedQuestions.map((question) => (
        <div
          className="mx-8 my-6"
          key={`${question.userId}${question.submitted.nanoseconds}`}
        >
          <div className="text-dolphin font-semibold pb-2">
            {question.userName}
          </div>
          <div className="font-semibold text-inky">{question.text}</div>
        </div>
      ))
    }

    return (
      <>
        <span className="pt-8 text-dolphin font-semibold px-8 pb-6">
          Your submitted questions
        </span>
        <div className="flex flex-col items-start text-left px-8">
          {sortedQuestions?.map((question) => (
            <span
              className="font-semibold text-inky py-6"
              key={`${question.userId}${question.submitted.nanoseconds}`}
            >
              {question.text}
            </span>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="h-full flex flex-col font-avenir-next">
      <div
        className="flex-auto flex flex-col overflow-auto h-full w-full items-start text-left"
        ref={questionsContainer}
      >
        {renderContent()}
      </div>
      <div className="bg-white flex flex-row items-center rounded-b-lg shadow-chat-input-container">
        <TextArea
          aria-label="Submit a question"
          className="bg-white text-dolphin focus:outline-none block w-full pl-4 appearance-none re20-none rounded-b-lg resize-none"
          placeholder="Submit a question"
          onChange={handleTextChange}
          value={text}
          maxRows={10}
          rows={1}
          onKeyPress={handlePressEnter}
        />
        <button
          type="button"
          className="focus:outline-none px-5 py-4"
          onClick={handleSubmit}
        >
          <SendIcon width="24" height="24" />
        </button>
      </div>
    </div>
  )
}

export default QAndA
