/**
 * The Header's background height need to follow the form / status form position according to the layout
 */

import React, { createContext, useState } from 'react'

interface HeaderAreaProps {
  /**
   * Element to be used as reference to the Header height
   */
  elementReference: HTMLElement | null | undefined
  /**
   * Set the Element reference to be used as the Header height
   */
  setElementReference: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >
}

const defaultValue: HeaderAreaProps = {
  elementReference: null,
  setElementReference: () => {
    throw new Error('Must be defined by using react useState hook!')
  },
}

const HeaderAreaContext = createContext<HeaderAreaProps>(defaultValue)
export default HeaderAreaContext

export const HeaderAreaProvider: React.FC = ({ children }) => {
  const [elementReference, setElementReference] = useState<HTMLElement | null>()

  return (
    <HeaderAreaContext.Provider
      value={{ elementReference, setElementReference }}
    >
      {children}
    </HeaderAreaContext.Provider>
  )
}
