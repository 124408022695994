import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

const useEventProperties = (event: string, refreshInterval?: number) => {
  const { data: eventState } = shared.useDocument<types.EventState>(
    `events/${event}/EventProperties/EventState`,
    {
      listen: true,
      revalidateOnFocus: true,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
      ...(refreshInterval ? { refreshInterval } : {}),
    }
  )

  const { data: eventMeta } = shared.useDocument<types.EventMeta>(
    `events/${event}/EventProperties/EventMeta`,
    {
      listen: true,
      revalidateOnFocus: true,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
      ...(refreshInterval ? { refreshInterval } : {}),
    }
  )

  return { eventState, eventMeta }
}

export default useEventProperties
