import * as shared from 'pericles-shared'
import * as types from 'pericles-types'

const useCurrentUser = () => {
  const authUser = shared.AuthContext.useContainer()

  const { data: user } = shared.useDocument<types.User>(
    `users/${authUser?.uid}`,
    { listen: true, suspense: true }
  )

  return user as types.User
}

const CurrentUserContext = shared.createContainer(useCurrentUser)

export default CurrentUserContext
